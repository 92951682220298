/**
 * @copyright 2019 @ DigiNet
 * @author ANHTAI
 * @create 05/18/2020
 * @Example
 */

import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import * as generalActions from "../../../../redux/general/general_actions";

import Config from "../../../../config";

import { Typography, Icon, Tooltip } from "diginet-core-ui/components";
import { useTheme, makeStyles } from "diginet-core-ui/theme";
import { Loading } from "../../../common/loading/loading";

const {
    colors: { info, success, danger, white },
    spacing,
} = useTheme();

const useStyles = makeStyles(() => ({
    iconBar: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
    },
    blockIcon: {
        borderRadius: spacing(12),
        width: spacing(12),
        height: spacing(12),
        color: white,
        display: "flex !important",
    },
    iconInfo: {
        position: "absolute",
        bottom: spacing(-2),
        right: spacing(-2),
    },
    transparent: {
        background: "transparent"
    },
}));

const W75F2002 = props => {
    const classes = useStyles();
    const { employeeID, dataYear, loading } = props;

    const [dataGeneralInformation, setdataGeneralInformation] = useState();

    const {
        BacklogLeave,
        SeniorityLeave,
        AnnualLeave,
        CompensationLeave,
        UsedBackLog,
        UsedSeniority,
        UsedAnnual,
        RemainBackLog,
        RemainSeniority,
        RemainAnnual,
        TotalLeave,
        UsedTotalLeave,
        TotalRemainLeave,
        UsedCompensation,
        TotalExpireNotUsed,
        RemainCompensation,
    } = dataGeneralInformation || {};

    const isMSS = useRef(!!Number(Config.getLocalStorage("MENUTYPEDHR")));

    useEffect(() => {
        loadGeneralInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataGeneralInformation === null) loadGeneralInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGeneralInformation, employeeID]);

    useEffect(() => {
        setdataGeneralInformation(null);
    }, [employeeID, dataYear]);

    const loadGeneralInformation = () => {
        const param = {
            ...(!isMSS ? { FormID: "W75F2000" } : {}),
            DivisionID: Config.getDivisionID(),
            TranMonth: dataYear ? dataYear?.TranMonth : Config.getHRTransMonth(),
            TranYear: dataYear ? dataYear?.TranYear : Config.getHRTransYear(),
            LeaveCycle: dataYear?.TranYear || 0,
            Language: Config.language || "84",
            EmployeeID: employeeID,
        };

        props.generalActions.getGridGeneralInformation(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            setdataGeneralInformation(data || []);
        });
    };

    const roundNumber = (num = 0) => Math.round(num * 100) / 100;

    const viewTooltip = (txt1 = 0, txt2 = 0, txt3 = 0, txt4 = 0, txt5 = undefined) => {
        return (
            <div>
                <Typography color={white}>
                    {Config.lang("Phep_Ton")}: {txt1}
                </Typography>
                <Typography color={white}>
                    {Config.lang("Phep_tham_nien")}: {txt2}
                </Typography>
                <Typography color={white}>
                    {Config.lang("Phep_nam")}: {txt3}
                </Typography>
                <Typography color={white}>
                    {Config.lang("Phep_bu")}: {txt4}
                </Typography>
                <Typography color={white}>
                    {!!txt5 ? `${Config.lang("Phep_het_han_chua_su_dung")}: ${txt5}` : ""}
                </Typography>
            </div>
        );
    };

    const data = [
        {
            name: Config.lang("Tong_phep"),
            value: roundNumber(TotalLeave),
            data: viewTooltip(BacklogLeave, SeniorityLeave, AnnualLeave, CompensationLeave),
            color: info,
        },
        {
            name: Config.lang("Phep_da_nghi_/_het_han"),
            value: roundNumber(UsedTotalLeave),
            data: viewTooltip(UsedBackLog, UsedSeniority, UsedAnnual, UsedCompensation, TotalExpireNotUsed),
            color: danger,
        },
        {
            name: Config.lang("Phep_con_lai"),
            value: roundNumber(TotalRemainLeave),
            data: viewTooltip(RemainBackLog, RemainSeniority, RemainAnnual, RemainCompensation),
            color: success,
        },
    ];

    return (
        <div className={`display_row valign-around full_w`}>
            {data.map((item, i) => {
                return (
                    <Tooltip key={i} title={item.data} arrow={true} textAlign={"left"}>
                        <div className={"display_col valign-middle align-center"}>
                            <Typography
                                type={"h3"}
                                className={`${classes.blockIcon} align-center valign-middle`}
                                style={{
                                    backgroundColor: item.color,
                                }}
                            >
                                <Loading visible={loading} size={spacing(5)} className={classes.transparent} />
                                {+Number(item.value ?? 0).toFixed(2)}
                                <Icon name={"Info"} className={classes.iconInfo} color="primary" />
                            </Typography>
                            <Typography className={"mgt2x"}>{item.name}</Typography>
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default compose(
    connect(null, dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
    }))
)(W75F2002);
