import React, {Component} from 'react';
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Filter from "../../../filter/filter";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import Api from "../../../../services/api";
import W52F1001 from "../W52F1001/W52F1001";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions";
import {useTheme} from "diginet-core-ui/theme";
import {
    Row,
    Col,
    ButtonIcon,
    DatePicker,
    Dropdown,
    Button,
    Image
} from 'diginet-core-ui/components';
import {makeStyles} from "diginet-core-ui/theme";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";

const {spacing} = useTheme();
const useStyles = makeStyles(() => ({
    dateInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    divText: {
        fontWeight: 500,
    },
}));

class W52F1000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            dataGrid: {
                rows: [],
                total: 0,
            },
            w84F3005PopupData: {
                TransID: null
            },
            loadingCboPeriod: false,
            loadingCboProject: false,
            loadingCboEmployee: false,
            loadingDataGrid: false,
            mode: "add",
            rowData: null,
            showW52F1001: false,
            showW84F3005Popup: false,
            DateFrom: null,
            DateTo: null,
            Employee: "",
            Project: "",
            AppStatusID: "",
            PeriodTime: "",
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterDataGrid = {
            skip: 0,
            limit: 10,
        }
        this.params = {
            FormID: "W52F1000",
            Language: Config.language || 84,
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W52F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    getData = (data, isReset = false) => ({
        total: _.get(data, "total", _.get(data, "length", 0)),
        rows: isReset ? this.state.dataCboEmployees.rows.concat(_.get(data, "rows", data)) : _.get(data, "rows", data)
    });

    loadDataGrid = async (voucher_id) => {
        const {DateFrom, DateTo, Employee, Project, AppStatusID, PeriodTime} = this.state;
        const {voucher_id: _voucher_id} = Config.getUrlParams(this.props);
        voucher_id = voucher_id ? voucher_id : _voucher_id;
        const params = {
            ...this.params,
            ..._.pick(this.filterDataGrid, ["skip", "limit"]),
            AppStatusID,
            PeriodTime,
            EmployeeID: Employee,
            ProjectID: Project,
            TransID: voucher_id || "",
            DateFrom,
            DateTo
        }
        this.setState({loadingDataGrid: true});
        const res = await Api.put("/w52f1000/load-form", params);
        this.setState({loadingDataGrid: false});
        const errMessage = _.get(res, "message", "");
        const data = _.get(res, "data", "");
        if (errMessage) {
            Config.popup.show("ERROR", errMessage);
            return false;
        }
        if (data) {
            this.setState({dataGrid: this.getData(data)})
        }
    }

    loadCboEmployees = (isReset = false) => {
        const {dataCboEmployees} = this.state;
        const params = {
            ...this.params,
            Type: "EmployeeID",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({loadingCboEmployee: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset = false) => {
        const {dataCboProjects} = this.state;
        const params = {
            ...this.params,
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({loadingCboProject: true});
        this.props.generalActions.getCboProjects(params, (err, data) => {
            this.setState({loadingCboProject: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0));
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total,
                    }
                })
            }
        });
    };

    loadCboPeriod = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
        };
        this.setState({loadingCboPeriod: true});
        this.props.W75F2010Actions.loadPeriod(param, (err) => {
            this.setState({loadingCboPeriod: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        this.props.generalActions.getCboAppStatus({...this.params, FormID: "STANDARD"}, (err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadCboAbsentType = () => {
        this.props.W75F2010Actions.loadAbsentType((err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    }

    componentDidUpdate () {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W52F1000",
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (data) => {
                if (_.get(data, "AppStatusID", 0) !== 0) {
                    this.onView({row: {data: data}});
                } else {
                    this.onEdit({row: {data: data}});
                }
            }
        }, this.props);
    }

    onView = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW52F1001: true
        });
    }

    onAdd = () => {
        this.setState({
            mode: "add",
            showW52F1001: true
        });
    };

    onEdit = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "edit",
            rowData: data,
            showW52F1001: true
        });
    };

    onCloseModal = (isReloadForm = false) => {
        if (isReloadForm) {
            this.loadDataGrid();
        }
        this.setState({
            showW52F1001: false
        });
    };

    onDeleteRow = (data) => {
        const params = {
            ...this.params,
            TransID: _.get(data, "TransID", ""),
            EmployeeID: _.get(data, "EmployeeID", "")
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            (async () => {
                this.setState({ loadingDataGrid: true });
                const res = await Api.put("/w52f1000/delete", params);
                this.setState({ loadingDataGrid: false });
                const data = _.get(res, "data", {});
                const errMessage = _.get(res, "message", _.get(data, "Message", "") || Config.lang("Loi_khong_xac_dinh"));
                const status = _.get(data, "Status", 1);
                if (errMessage || status) {
                    Config.popup.show("ERROR", errMessage);
                    return false;
                }
                if (!_.isEmpty(data)) {
                    const dataMessage = _.get(data, "Message", Config.lang("Loi_chua_xac_dinh"));
                    const dataStatus = _.get(data, "Status", 1);
                    if (dataMessage || dataStatus) {
                        Config.popup.show("ERROR", dataMessage);
                        return false;
                    }
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                    this.loadDataGrid();
                }
            })();
        });
    }

    onFilterChange = (key, data) => {
        let value = data?.value ?? data?.target?.value ?? "";
        switch(key){
            case "DateFrom":
            case "DateTo":
                value = data?.value ?? data?.target?.value ?? null;
                this.setState({[key]: value});
                break;
            default:
                this.setState({[key]: value});
                break;
        }
    }

    onChangePage = (page) => {
        this.filterDataGrid.skip = page * this.filterDataGrid.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage) => {
        this.filterDataGrid.skip = 0;
        this.filterDataGrid.limit = perPage;
        this.loadDataGrid();
    };

    onFilter = () => {
        this.loadCboEmployees();
        this.loadCboAbsentType();
        this.loadCboProjects();
        this.loadCboAppStatus();
        this.loadCboPeriod();
    }

    onSearch = () => {
        this.loadDataGrid();
    };

    onHistoryApproval = (e, data) => {
        this.setState({ w84F3005PopupData: { TransID: data ? data.TransID:null }, showW84F3005Popup: true })
    };

    renderEmpProfile = (e) => {
        const classes = useStyles();
        const {data} = e.row;
        const date = Config.convertDate(data.EntryDate, "", "LT, DD/MM/YYYY", true);
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.dateInfo}>
                        <Image width={spacing(3)} height={spacing(3)} src={require('../../../../assets/images/icon-calendar.svg')}/>
                        <div style={{paddingLeft: '8px'}} className={"date-text-info"}>{data.EntryDate && date}</div>
                    </div>
                </div>
            </div>
        )
    };

    renderInfo = (e) => {
        const classes = useStyles();
        const {data} = e.row;
        const arrDataField = _.filter([
            {
                label: Config.lang("Du_an"),
                value: _.get(data, "ProjectID", "") ? _.get(data, "ProjectName", "") : "",
            },
            {
                label: Config.lang("Ban_giao"),
                value: _.get(data, "HandOverID", "") ? _.get(data, "HandOverName", "") : "",
            },
            {
                label: Config.lang("Ky_luong"),
                value: _.get(data, "Period", ""),
            },
            {
                label: Config.lang("Ghi_chu"),
                value: _.get(data, "Note", ""),
            },
            {
                label: Config.lang("Nguoi_lap"),
                value: _.get(data, "CreateUserName", ""),
            },
            {
                label: Config.lang("Nguoi_duyet"),
                value: _.join(_.compact([
                    _.get(data, "ApproverName", ""),
                    _.get(data, "DepartmentID", ""),
                    Config.convertDate(_.get(data, "ApprovalDate", ""), "", "LT, DD/MM/YYYY", true)
                ]), " - ")
            },
            {
                label: Config.lang("Ghi_chu_duyet"),
                value: _.get(data, "ApproverNote", "")
            }
        ], item => Boolean(item.value));
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {_.map(arrDataField, (item, i) =>
                    <div key={i}>
                        {_.join([item.label, item.value], ": ")}
                    </div>
                )}
            </div>
        )
    };

    renderStatus = (e) => {
        const {data} = e.row;
        return (
            <Status data={data}/>
        );
    };

    renderAction = (e) => {
        const {data} = e.row;
        const {iPermission} = this.state;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"History"}
                    className={'mgr5'}
                    onClick={() => this.onHistoryApproval(e, data)}
                />
                <ButtonIcon disabled={iPermission < 1}
                            name="view"
                            circular
                            viewType={'text'}
                            className={'mgr5'}
                            onClick={() => this.onView(e)}
                />
                <ButtonIcon disabled={(data && Number(data.AppStatusID) !== 0) || iPermission < 3}
                            name="delete"
                            circular
                            viewType={'text'}
                            onClick={() => this.onDeleteRow(data)}
                />
            </div>
        )
    };

    renderFilter = () => {
        const {getCboPeriod, getCboAppStatus} = this.props;
        const {
            dataCboEmployees,
            dataCboProjects,
            loadingCboProject,
            loadingCboPeriod,
            loadingCboEmployee,
            Project,
            AppStatusID,
            Employee,
            PeriodTime,
            DateTo,
            DateFrom
        } = this.state;
        return (
            <Filter
                isUseDDCore
                onOpened={this.onFilter}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={
                    () => {
                        return (
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        label={Config.lang("Ky_luong")}
                                        dataSource={getCboPeriod}
                                        displayExpr={"PeriodTime"}
                                        valueExpr={"PeriodTime"}
                                        onChange={(e) => this.onFilterChange("PeriodTime", e)}
                                        clearAble
                                        viewType="outlined"
                                        value={PeriodTime}
                                        loading={loadingCboPeriod}
                                        searchDelayTime
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <DatePicker
                                        label={Config.lang("Tu")}
                                        max={DateTo}
                                        style={{marginLeft: "5px"}}
                                        onChange={(e) => this.onFilterChange("DateFrom", e)}
                                        clearAble
                                        placeholder={"DD/MM/YYYY"}
                                        displayFormat={"DD/MM/YYYY"}
                                        viewType="outlined"
                                        value={DateFrom}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <DatePicker
                                        label={Config.lang("Den")}
                                        min={DateFrom}
                                        style={{marginLeft: "5px"}}
                                        onChange={(e) => this.onFilterChange("DateTo", e)}
                                        clearAble
                                        placeholder={"DD/MM/YYYY"}
                                        displayFormat={"DD/MM/YYYY"}
                                        viewType="outlined"
                                        value={DateTo}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        label={Config.lang("Trang_thai_duyet")}
                                        dataSource={getCboAppStatus}
                                        displayExpr={"AppStatusName"}
                                        valueExpr={"AppStatusID"}
                                        onChange={(e) => this.onFilterChange("AppStatusID", e)}
                                        clearAble
                                        viewType="outlined"
                                        value={AppStatusID}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        dataSource={dataCboEmployees.rows}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        displayExpr={"{EmployeeID}-{EmployeeName}"}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        value={Employee}
                                        viewType={'outlined'}
                                        loading={loadingCboEmployee}
                                        label={Config.lang('Nhan_vien')}
                                        clearAble
                                        onChange={e => this.onFilterChange("Employee", e)}
                                        onInput={(e) => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                        searchDelayTime
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        dataSource={dataCboProjects?.rows}
                                        skip={this.filterCboProjects.skip}
                                        limit={this.filterCboProjects.limit}
                                        displayExpr={'{ProjectID}-{ProjectName}'}
                                        keyExpr={'ProjectID'}
                                        valueExpr={'ProjectID'}
                                        value={Project}
                                        loading={loadingCboProject}
                                        viewType={'outlined'}
                                        label={Config.lang('Du_an')}
                                        clearAble
                                        onChange={e => this.onFilterChange("Project", e)}
                                        onInput={(e) => {
                                            this.filterCboProjects.strSearch = e.target.value;
                                            this.filterCboProjects.skip = 0;
                                            this.loadCboProjects(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboProjects.skip = e.skip;
                                            this.filterCboProjects.limit = e.limit;
                                            this.loadCboProjects();
                                        }}
                                        searchDelayTime
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} className="display_row valign-middle">
                                    <Button
                                        label={Config.lang("Tim_kiem")}
                                        startIcon={"search"}
                                        size={"medium"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        onClick={this.onSearch}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                }
            />
        )
    };

    render() {
        const {
            dataGrid,
            showW52F1001,
            rowData,
            mode,
            iPermission,
            loadingDataGrid,
            showW84F3005Popup,
            w84F3005PopupData,
        } = this.state;
        const {skip, limit} = this.filterDataGrid;
        if (iPermission <= 0) return null;
        return (
            <>
                <W84F3005
                    open={showW84F3005Popup}
                    onClose={e => this.setState({ showW84F3005Popup: false })}
                    FormID="W52F1000"
                    TransID={w84F3005PopupData.TransID}
                />
                {showW52F1001 &&
                    <W52F1001 mode={mode} data={rowData} onClose={this.onCloseModal} open={showW52F1001}/>
                }
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar
                    title={Config.lang("Sai_sot_cong")}
                >
                    <ButtonGeneral
                        name={Config.lang('Them')}
                        typeButton={'add'}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        disabled={iPermission < 1}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{border: 'none'}}
                    dataSource={dataGrid.rows}
                    keyExpr={"TransID"}
                    totalItems={dataGrid.total}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    onDbCellClick={this.onEdit}
                    loading={loadingDataGrid}
                    hoverStateEnabled={true}
                    height={Config.getHeightGrid()}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus}/>
                    <Column cellRender={this.renderAction}/>
                </GridContainer>
            </>
        );
    }

    componentDidMount = () => {
        this.loadPermission();
        this.loadDataGrid();
        if (this.state.iPermission <= 0) return;
    }
}

export default compose(connect((state) => ({
    getCboEmployees: state.general.getCboEmployees,
    getCboProjects: state.general.getCboProjects,
    getCboAppStatus: state.general.getCboAppStatus,
    getCboPeriod: state.W75F2010.getCboPeriod,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch)
})))(W52F1000);