/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 9/1/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { MForm } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import CDN from "../../../CDN";
import _ from "lodash";
import {
    Dropdown,
    Typography,
    Avatar,
    Label,
    ButtonIcon,
    NumberInput,
    TextInput,
    Button,
    ModalBody,
    ModalFooter,
    Attachment,
    Row,
    Col,
} from "diginet-core-ui/components";
import { useTheme } from "diginet-core-ui/theme";
const { spacing } = useTheme();
class W75F2010Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            absentVoucherID: "",
            voucherStatus: false,
            employee: null,
            project: null,
            note: "",
            PeriodTime: "",
            absentTypeRow: [
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: "",
                },
            ],
            dataAbsentType: [],
            dataOldAttachments: [],
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            disabled: false,
            error: {},
            loading: false,
            loadingAttachments: false,
            loadingProjects: false,
            uploading: false,
            employeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProjects = {
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID: "W75F2010",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch,
        };
        this.setState({ loading: true, loadingProjects: true });
        this.props.generalActions.getCboProjects(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ loading: false, loadingProjects: false });
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboAbsentType = () => {
        this.setState({ loading: true });
        this.props.W75F2010Actions.loadAbsentType((error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ loading: false, dataAbsentType: data });
        });
    };

    loadCboPeriods = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
        };
        this.setState({ loading: true });
        this.props.W75F2010Actions.loadPeriod(param, error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = () => {
        const { data } = this.props;
        const param = {
            DivisionID: Config.getDivisionID(),
            VoucherID: data && data.AbsentVoucherID ? data.AbsentVoucherID : "",
            FormID: "W13F1000",
            Language: Config.language || "84",
        };

        this.setState({ loading: true });
        this.props.W75F2010Actions.getForm(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                this.setState(
                    {
                        employee: user ? user : data,
                        absentVoucherID: data.AbsentVoucherID,
                        absentTypeRow: data.AbsentType,
                        PeriodTime: data.Period,
                        note: data.Note,
                        project: data.ProjectID,
                    },
                    () => {
                        this.loadCboPeriods();
                    }
                );
            }

            this.setState({ loading: false });
        });
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: data && data.AbsentVoucherID ? data.AbsentVoucherID : "",
        };
        this.setState({ loadingAttachments: true });
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            this.setState({ loadingAttachments: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    handleChange = (key, e, i) => {
        if (!key) return false;
        const { error, absentTypeRow } = this.state;
        switch (key) {
            case "AbsentType": {
                const { getCboAbsentType } = this.props;
                absentTypeRow[i] = {
                    ...absentTypeRow[i],
                    AbsentTypeID: e.data.AbsentTypeID,
                    AbsentTypeName: e.data.AbsentTypeName,
                };
                const listSelected = absentTypeRow.map(m => {
                    return m.AbsentTypeID;
                });
                this.setState({
                    absentTypeRow: absentTypeRow,
                    dataAbsentType: getCboAbsentType.filter(data => {
                        return listSelected.indexOf(data.AbsentTypeID) === -1;
                    }),
                    error: {
                        ...error,
                        [`AbsentTypeID${i}`]: "",
                    },
                });
                break;
            }
            case "Number": {
                const absentTypeRowT = [...absentTypeRow];
                absentTypeRowT[i].Number = e;
                this.setState({
                    absentTypeRow: [
                        ...this.state.absentTypeRow.slice(0, i),
                        { ...this.state.absentTypeRow[i], Number: e },
                        ...this.state.absentTypeRow.slice(i + 1)
                    ]
                });
                break;
            }
            case "EmployeeID": {
                this.setState(
                    {
                        employee: e.data,
                    },
                    () => {
                        this.setState({
                            error: {
                                ...this.state.error,
                                [key]: Object.keys(e.data)?.length > 0 ? "" : Config.lang("Truong_nay_bat_buoc_nhap"),
                            },
                        });
                    }
                );
                break;
            }
            case "PeriodTime": {
                this.setState(
                    {
                        PeriodTime: e.value,
                    },
                    () => {
                        this.setState({
                            error: {
                                ...this.state.error,
                                [key]: e.value ? "" : Config.lang("Truong_nay_bat_buoc_nhap"),
                            },
                        });
                    }
                );
                break;
            }
            case "ProjectID": {
                this.setState({
                    project: e.value,
                });
                break;
            }
            case "Note": {
                this.setState({
                    note: e.target.value,
                });
                break;
            }
            default:
                break;
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.openFileDialog();
        }
    };

    // onChangeAttachments = (e) => {
    //   this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
    //   if (e.deletedFiles && e.deletedFiles.length > 0) {
    //     this.deletedFile.push(e.deletedFiles[0]);
    //     const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
    //     this.setState(prevState => ({
    //       dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
    //         return _arrRemove.indexOf(att.AttachmentID) < 0;
    //       })
    //     }));
    //   }
    // };

    onChangeAttachments = e => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    setStateErrorText(objValue, key) {
        const { errorHelperText } = this.state;
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...errorHelperText,
                ...objValue,
            },
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = async () => {
        const { mode, getCboPeriod } = this.props;
        const { employee, note, PeriodTime, project, absentTypeRow, absentVoucherID } = this.state;
        const dataResUploadFile = await this._uploadFile(this.attachments, true);
        const arrAttachments = this._getAttachments(dataResUploadFile);
        const getPeriodItem = _.filter(getCboPeriod, item => _.get(item, "PeriodTime", "") === PeriodTime);

        const validateRules = [
            {
                name: "EmployeeID",
                rules: "isRequired",
                value: employee && employee.EmployeeID ? employee.EmployeeID : "",
            },
            {
                name: "PeriodTime",
                rules: "isRequired",
                value: _.size(getPeriodItem) > 0 ? getPeriodItem : null,
            },
        ];

        absentTypeRow.forEach((i, index) => {
            validateRules.push({
                name: `AbsentTypeID${index}`,
                rules: "isRequired",
                value: i.AbsentTypeID,
            });
            validateRules.push({
                name: `Number${index}`,
                rules: "isRequired",
                value: i.Number,
            });
        });

        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false;

        this.setState({ loading: true });

        const param = {
            AbsentVoucherID: absentVoucherID ? absentVoucherID : "",
            DivisionID: _.get(getPeriodItem, "[0]DivisionID", ""),
            EmployeeID: _.get(employee, "EmployeeID", ""),
            ProjectID: project ?? "",
            TranMonth: _.get(getPeriodItem, "[0]TranMonth", ""),
            TranYear: _.get(getPeriodItem, "[0]TranYear", ""),
            ModuleID: "D13",
            HostID: "",
            Note: note,
            NoteU: note,
            TransTypeID: "W75F2010",
            data: JSON.stringify(absentTypeRow.map(item => ({
                AbsentTypeID: item.AbsentTypeID || "",
                NumberOfDays: item.Number || "",
            }))),
            arrAttachment: JSON.stringify(arrAttachments),
        };
        this.props.W75F2010Actions[mode === "edit" ? "editAbsent" : "saveAbsent"](param, async (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                this.setState({ loading: false });
                return false;
            }
            if (data) {
                if (data && data.Status === 1) {
                    Config.popup.show("INFO", data.Message);
                    this.setState({ loading: false });
                    return false;
                } else {
                    this._removeCDN();
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    this.onClose(true);
                }
            }
            this.setState({ loading: false });
        });
    };

    _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = file => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    resetForm = () => {
        this.setState({
            absentVoucherID: "",
            employee: null,
            project: null,
            note: "",
            periodItem: null,
            absentTypeRow: [
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: "",
                },
            ],
            error: {},
        });
    };

    onClose = (isReload = false) => {
        const { onClose } = this.props;
        this.resetForm();
        if (onClose) onClose(isReload);
    };

    onAddRow = () => {
        this.setState({
            absentTypeRow: [
                ...this.state.absentTypeRow,
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: "",
                },
            ],
        });
    };

    onDeleteRow = i => {
        const { getCboAbsentType } = this.props;
        let { absentTypeRow } = this.state;
        absentTypeRow = absentTypeRow.filter((_, j) => j !== i);
        const listSelected = absentTypeRow.map(m => {
            return m.AbsentTypeID;
        });
        this.setState({
            absentTypeRow: absentTypeRow,
            dataAbsentType: getCboAbsentType.filter(data => {
                return listSelected.indexOf(data.AbsentTypeID) === -1;
            }),
        });
    };

    componentDidMount() {
        const { mode, data } = this.props;
        this.loadCboProjects();
        this.loadCboAbsentType();
        if (mode !== "add") {
            this.loadForm();
            this.loadAttachments();
            this.setState({
                disabled: mode === "view",
            });
            if (data) {
                if (Number(data.AppStatusID) !== 0) this.setState({ disabled: true });
                if (data.VoucherStatus === null || data.VoucherStatus === 1) {
                    this.setState({ voucherStatus: false });
                } else {
                    this.setState({ voucherStatus: true });
                }
            }
        } else {
            this.loadCboEmployees();
            this.loadCboPeriods();
        }
    }

    renderAbsentType = () => {
        const { mode } = this.props;
        const { dataAbsentType, absentTypeRow, error, loading } = this.state;

        return absentTypeRow?.map((item, i) => {
            let dataTemp = [...dataAbsentType];
            if (item && item.AbsentTypeID) {
                dataTemp.unshift(item);
            }

            return (
                <React.Fragment key={i}>
                    <Col xs={8} sm={8} md={8} lg={8} className={`${!i ? "pdt0" : "pdt1x"} display_row`}>
                        <ButtonIcon
                            className={"mgt2x mgr1x"}
                            viewType={"ghost"}
                            name={"delete"}
                            disabled={absentTypeRow.length < 1}
                            size="small"
                            onClick={() => this.onDeleteRow(i, item.AbsentTypeID)}
                        />
                        <Dropdown
                            style={{ flex: 1 }}
                            error={error && error[`AbsentTypeID${i}`]}
                            dataSource={dataTemp}
                            displayExpr={"{AbsentTypeID} - {AbsentTypeName}"}
                            valueExpr={"AbsentTypeID"}
                            disabled={loading || mode === "view"}
                            value={item.AbsentTypeID}
                            onChange={e => this.handleChange("AbsentType", e, i)}
                        />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} className={!i ? "pdt0" : "pdt1x"}>
                        <NumberInput
                            disabled={mode === "view"}
                            error={error && error[`Number${i}`]}
                            onChange={e => this.handleChange("Number", e.value, i)}
                            value={item.Number}
                            disabledNegative
                            decimalDigit={2}
                            thousandSeparator={","}
                        />
                    </Col>
                </React.Fragment>
            );
        });
    };

    loadCboEmployees = isReset => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2010",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    render() {
        const { getCboPeriod, mode } = this.props;
        const {
            uploading,
            employee,
            project,
            error,
            disabled,
            loading,
            loadingAttachments,
            note,
            absentTypeRow,
            voucherStatus,
            dataOldAttachments,
            dataCboProjects,
            loadingProjects,
            PeriodTime,
            dataCboEmployees,
            employeeLoading,
        } = this.state;
        return (
            <React.Fragment>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                required
                                error={error && error["EmployeeID"]}
                                valueObjectDefault={typeof employee === "object" ? employee : null}
                                dataSource={dataCboEmployees.rows}
                                displayExpr={"EmployeeName"}
                                subText={"EmployeeID"}
                                valueExpr={"EmployeeID"}
                                loading={employeeLoading}
                                total={dataCboEmployees.total}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                renderSelectedItem={data => {
                                    return (
                                        <div className={"display_row align-center"}>
                                            <Avatar
                                                readOnly
                                                width={24}
                                                height={24}
                                                data={data?.data}
                                                src={`${Config.getUserPicture(data?.data?.UserPictureURL)}`}
                                            />
                                            <Typography
                                                hoverTooltip
                                                className={"mgl5"}
                                                lineClamp={1}
                                            >{`${data?.data?.EmployeeID} - ${data?.data?.EmployeeName}`}</Typography>
                                        </div>
                                    );
                                }}
                                value={employee?.EmployeeID}
                                disabled={loading}
                                label={Config.lang("Nhan_vien")}
                                readOnly={disabled}
                                iconExpr={{
                                    key: "UserPictureURL",
                                    prefix: Config.getCDNPath(),
                                }}
                                onChange={e => this.handleChange("EmployeeID", e)}
                                onInput={e => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={e => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>

                        <Col xs={8} sm={8} md={8} lg={8}>
                            <Dropdown
                                allowSearch
                                dataSource={dataCboProjects.rows}
                                total={dataCboProjects.total}
                                skip={this.filterCboProjects.skip}
                                limit={this.filterCboProjects.limit}
                                displayExpr={"{ProjectID} - {ProjectName}"}
                                valueExpr={"ProjectID"}
                                value={project}
                                loading={loadingProjects}
                                disabled={loading || loadingProjects}
                                readOnly={disabled}
                                label={Config.lang("Du_an")}
                                renderSelectedItem={"ProjectName"}
                                onChange={e => this.handleChange("ProjectID", e)}
                                onInput={e => {
                                    this.filterCboProjects.strSearch = e.target.value;
                                    this.filterCboProjects.skip = 0;
                                    this.loadCboProjects(true);
                                }}
                                onLoadMore={e => {
                                    this.filterCboProjects.skip = e.skip;
                                    this.filterCboProjects.limit = e.limit;
                                    this.loadCboProjects();
                                }}
                            />
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Dropdown
                                error={error && error["PeriodTime"]}
                                dataSource={getCboPeriod}
                                displayExpr={"PeriodTime"}
                                valueExpr={"PeriodTime"}
                                disabled={loading}
                                readOnly={disabled}
                                value={PeriodTime}
                                required={true}
                                label={Config.lang("Ky_luong")}
                                onChange={e => this.handleChange("PeriodTime", e)}
                            />
                        </Col>

                        <Col xs={8} sm={8} md={8} lg={8} className={"display_row align-center"}>
                            <Label>{Config.lang("Loai_thu_nhap")}</Label>
                            <ButtonIcon
                                viewType={"text"}
                                circular
                                name={"Add"}
                                color="primary"
                                size={"tiny"}
                                className={"mgl1x"}
                                onClick={() => this.onAddRow()}
                            />
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Label style={{ marginBottom: spacing(-4) }}>{Config.lang("So_luong")}</Label>
                        </Col>

                        {/*Render loại thu nhập */}
                        {this.renderAbsentType()}

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Config.lang("Ghi_chu")}
                                value={note}
                                disabled={loading}
                                readOnly={disabled}
                                onChange={e => this.handleChange("Note", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                ref={ref => (this.attRef = ref)}
                                maxFile={5}
                                maxSize={"5mb"}
                                multiple
                                unitSize={"mb"}
                                data={dataOldAttachments}
                                disabled={disabled || loadingAttachments}
                                onChange={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                {mode !== "view" && (
                    <ModalFooter style={{ justifyContent: "space-between" }}>
                        <ButtonIcon
                            viewType={"text"}
                            circular
                            name={"Attachment"}
                            className={"mgr1x"}
                            disabled={loading}
                            onClick={this.onAttachment}
                        />
                        {/*Khi xóa hết các dòng absentTypeRow thì sẽ disable nút lưu => BA yêu cầu*/}
                        <Button
                            color={"info"}
                            viewType={"filled"}
                            label={Config.lang("Luu")}
                            startIcon={"save"}
                            disabled={loading || absentTypeRow.length < 1 || voucherStatus || uploading}
                            onClick={this.onSave}
                        />
                    </ModalFooter>
                )}
            </React.Fragment>
        );
    }
}

W75F2010Popup.propTypes = {
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func,
};

export default compose(
    connect(
        state => ({
            getCboProjects: state.general.getCboProjects,
            getForm: state.W75F2010.getForm,
            getCboPeriod: state.W75F2010.getCboPeriod,
            getCboAbsentType: state.W75F2010.getCboAbsentType,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch),
        })
    )
)(W75F2010Popup);
