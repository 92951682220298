import React                                        from "react";
import {bindActionCreators, compose}                from "redux";
import {withStyles, FormControl, Input, InputLabel} from "@material-ui/core";
import PropTypes                                    from 'prop-types';
import ButtonGeneral                                from "../../../common/button/button-general";
import Config                                       from "../../../../config";
import {connect}                                    from "react-redux";
import * as W75F2005Actions                         from "../../../../redux/W75/W75F2005/W75F2005_actions";
import PopoverAction                                from "../../../grid-container/popover-action";
import FormHelperText                               from "@material-ui/core/FormHelperText";
import {MForm}              from "../../../common/form-material";
const styles = theme => {
    return {
        card: {
            width: "590px",
            height: "178px",
            padding: "37px 28px 0px 28px",
            [theme.breakpoints.down('xs')]: {
                width: "300px",
                height: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    }
};


class PopoverW752005 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            errorNote: ""
        };
    }

    onSave = async (mode,nameMode) => {
        const {data, type, funcLoadGrid, setLoading} = this.props;
        const {notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if(nameMode === "Refuse") {
            if (validate && validate.errorNote) {
                this.setState(validate);
                return false;
            }
        }

        const params = {
            DivisionID: Config.getDivisionID(),
            Language: Config.language || 84,
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            Mode: mode,
            TransID: data.TransID,
            ApproveNotes: notes,
            EmployeeID: data.EmployeeID,
            Approval: type === "check" ? 1 : 0,
            NotApproval: type === "check" ? 0 : 1,
        };
        setLoading(true);
        await this.props.W75F2005Actions.saveData(params, (errors, data) => {
        setLoading(false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;

            }
            if (data) {
                if (data.Status === 0) {
                    Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 3000);
                    funcLoadGrid && funcLoadGrid();
                } else {
                    const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                    return false;
                }
            }
        });

        if (this.popover) this.popover.instance.hide();
    };

    onCheck = () => {
        const {anchorEl} = this.props;
        if (this.popover) this.popover.instance.show(anchorEl);
    };

    onClose = () => {
        if (this.popover) this.popover.instance.hide();
    };

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    render() {
        const { type, classes, data} = this.props;
        const { notes, errorNote } = this.state;
        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    onHiding={() => this.setState({notes: "", errorNote: "" })}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes} style={{padding: "0px 0px 21px 0px"}}>
                            <InputLabel
                                color={type === "check" ? "primary" : "secondary"}
                                shrink={true}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                }}
                                classes={{shrink: classes.shrink}}
                            >
                                {Config.lang("DHR_Ghi_chu_duyet")}
                            </InputLabel>
                            <Input fullWidth={true} classes={{underline: classes.underline}}
                                   color={type === "check" ? "primary" : "secondary"}
                                   value={notes}
                                   multiline={true}
                                   rowsMax={3}
                                   onChange={this.onNoteChange}
                            >
                            </Input>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                        {
                            data ?
                                (data.AppStatusID === "2" && data.IsCancelApproval === false) || (data.AppStatusID === "4" && data.IsCancelApproval === false)
                                    ?
                                    <ButtonGeneral
                                        name={Config.lang("DHR_Bo_duyet")}
                                        color={"danger"}
                                        variant={"contained"}
                                        className="pull-right"
                                        style={{textTransform: 'uppercase'}}
                                        size={"large"}
                                        onClick={()=>this.onSave(3,"Cancel")}
                                    />
                                    : type === "check"
                                    ?
                                    <ButtonGeneral
                                        name={Config.lang("DHR_Duyet")}
                                        color={"primary"}
                                        variant={"contained"}
                                        className="pull-right"
                                        style={{textTransform: 'uppercase'}}
                                        size={"large"}
                                        onClick={()=>this.onSave(1,"Approve")}
                                    />
                                    :
                                    <ButtonGeneral
                                        name={Config.lang("DHR_Tu_choi_W75")}
                                        color={"danger"}
                                        variant={"contained"}
                                        className="pull-right"
                                        style={{textTransform: 'uppercase'}}
                                        size={"large"}
                                        onClick={()=>this.onSave(2, "Refuse")}
                                    />
                                : ""
                        }
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }

}

PopoverW752005.propTypes = {
    className: PropTypes.string,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
};

export default compose(connect((state) => ({
        saveData: state.W75F2005.saveData,
        loadGrid: state.W75F2005.loadGrid
    }),
    (dispatch) => ({
        W75F2005Actions: bindActionCreators(W75F2005Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(PopoverW752005);
