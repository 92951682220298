import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import * as generalActions from "../../../../redux/general/general_actions";
import CDN from "../../../CDN";
import * as W29F2006Actions from "../../../../redux/W2X/W29F2006/W29F2006_actions";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions";
import _ from "lodash";
import Api from "../../../../services/api";
import {
    Avatar,
    Dropdown,
    Typography,
    Attachment,
    Row,
    Col,
    TextInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonIcon, NumberInput, Label,
} from 'diginet-core-ui/components';
import {makeStyles} from "diginet-core-ui/theme"

const useStyle = makeStyles(()=> ({
    unit: {
        width: 50,
    }
}));

const emptyAbsentTypeDate = {
    AbsentTypeDateID: "",
    Amount: "",
    Unit: "",
};

class W52F1001 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            validation: {
                Employee: "",
                PeriodTime: "",
                Project: "",
                HandOverID: "",
                absentTypeDate: [{AbsentTypeDateID: "", Amount: ""}]
            },
            dataCboHandovers: [],
            dataOldAttachments: [],
            loadingForm: false,
            loadingCboEmployee: false,
            loadingCboProject: false,
            loadingCboHandover: false,
            loadingCboPeriod: false,
            loadingCboAbsentType: false,
            loadingAttachments: false,
            dataForm: {
                Employee: null,
                Project: null,
                HandOverID: "",
                Notes: "",
                PeriodTime: _.join([Config.getHRTransMonth(), Config.getHRTransYear()], "/"),
                absentTypeDate: [{
                    AbsentTypeDateID: "",
                    Amount: "",
                    Unit: "",
                }],
            }
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.params = {
            FormID: "W52F1001",
            Language: Config.language || 84,
        }
        this.cboAbsentTypeIDFilter = [];
    }

    getData = (data, isReset = false) => ({
        total: _.get(data, "total", _.get(data, "length", 0)),
        rows: isReset ? this.state.dataCboEmployees.rows.concat(_.get(data, "rows", data)) : _.get(data, "rows", data)
    });

    getIndexDataCboAbsentType = (id) => {
        const {getCboAbsentType} = this.props;
        return _.findIndex(getCboAbsentType, itemCbo => itemCbo.AbsentTypeID === id);
    }

    loadSetting = () => {
        this.props.generalActions.loadSetting(err => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
    }

    loadForm = async () => {
        const {data} = this.props;
        const {validation} = this.state;
        const params = {
            ...this.params,
            VoucherID: _.get(data, "TransID", ""),
            Type: null
        }
        this.setState({loadingForm: true});
        const res = await Api.put('/w52f1001/load-popup', params);
        this.setState({loadingForm: false});
        const errMessage = _.get(res, "message", "");
        const resData = _.get(res, "data", {});
        if (errMessage) {
            Config.popup.show("ERROR", errMessage);
            return false;
        }
        if (!_.isEmpty(resData)) {
            const user = Config.getUser({EmployeeID: data.EmployeeID});
            const EmployeeID = _.get(resData, "EmployeeID", _.get(data, "EmployeeID", ""));
            const EmployeeName = _.get(resData, "EmployeeName", _.get(data, "EmployeeName", ""));
            const UserPictureURL = _.get(user, "UserPictureURL", _.get(data, "UserPictureURL", ""));
            const ProjectID = _.get(resData, "ProjectID", _.get(data, "ProjectID", ""));
            const ProjectName = _.get(resData, "ProjectName", _.get(data, "ProjectName", ""));
            const HandOverID = _.get(resData, "HandOverID", "");
            const PeriodTime = _.get(resData, "Period", _.get(data, "Period", ""))
            const Notes = _.get(resData, "Notes", "");
            const absentTypeDate = _.get(resData, "AbsentTypeDate", [{
                AbsentTypeDateID: "",
                Amount: "",
                Unit: "",
            }]);
            const employeeSelected = {EmployeeID, EmployeeName, UserPictureURL};
            const projectSelected = {ProjectID, ProjectName};
            if (PeriodTime && ProjectID) this.loadCboHandOver({PeriodTime, ProjectID});
            this.cboAbsentTypeIDFilter = _.reduce(absentTypeDate, (acc, item) => {
                acc = [...acc, item.AbsentTypeDateID];
                return acc;
            }, []);
            this.setState({
                validation: {
                    ...validation,
                    absentTypeDate: _.map(absentTypeDate, () => _.omit(emptyAbsentTypeDate, ["Unit"]))
                },
                dataForm: {
                    Employee: employeeSelected.EmployeeID ? employeeSelected : null,
                    Project: projectSelected.ProjectID ? projectSelected : null,
                    absentTypeDate,
                    HandOverID,
                    PeriodTime,
                    Notes,
                }
            })
        }
    }

    loadCboEmployees = (isReset = false) => {
        const {dataCboEmployees} = this.state;
        const params = {
            ...this.params,
            FormID: "W52F1000",
            Type: "EmployeeID",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({loadingCboEmployee: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const {dataCboProjects} = this.state;
        const param = {
            ...this.params,
            FormID: "W52F1000",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({loadingCboProject: true});
        this.props.generalActions.getCboProjects(param, (err, data) => {
            this.setState({loadingCboProject: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0));
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total,
                    }
                })
            }
        });
    };

    loadCboAbsentType = () => {
        this.setState({loadingCboAbsentType: true});
        this.props.W75F2010Actions.loadAbsentType((err) => {
            this.setState({loadingCboAbsentType: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadCboHandOver = async (params = {}) => {
        const {dataForm} = this.state;
        const periodTime = _.get(params, "PeriodTime", _.get(dataForm, "PeriodTime", ""));
        const getTransMonthYear = periodTime ? periodTime.split("/") : [];
        const getProjetID = _.get(params, "ProjectID", _.get(dataForm, "Project.ProjectID", ""))
        if (!_.isEmpty(getTransMonthYear) && getProjetID) {
            const res = await Api.put("/w52f1001/load-cbo-handover", {
                TranMonth: getTransMonthYear[0],
                TranYear: getTransMonthYear[1],
                ProjectID: getProjetID
            });
            const resMessageErr = _.get(res, "message", _.get(res, "data.Message", ""));
            const data = _.get(res, "data", []);
            if (resMessageErr) {
                Config.popup.show('ERROR', resMessageErr);
                return false;
            }
            if (!_.isEmpty(data)) {
                this.setState({
                    dataCboHandovers: data
                })
            }
        }
    };

    loadCboPeriods = () => {
        const param = {
            DivisionID: Config.getDivisionID()
        };
        this.setState({loadingCboPeriod: true});
        this.props.W75F2010Actions.loadPeriod(param, (err) => {
            this.setState({loadingCboPeriod: false});
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
        });
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: _.get(data, "TransID", ""),
        };
        this.setState({loadingAttachments: true});
        this.props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            this.setState({loadingAttachments: false});
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    handleChange = (key, data, i = -1) => {
        const {dataForm, validation} = this.state;
        let stateKey = key;
        let value = _.get(data, "value", _.get(data, "target.value", ""));
        const validateField = ["PeriodTime", "Employee", "Project", "HandOverID"];
        const requiredText = Config.lang("Truong_nay_bat_buoc_nhap");
        let errorState = {};
        if (_.includes(validateField, key)) {
            errorState = {
                [key]: _.isEmpty(value) ? requiredText : ""
            }
        }
        switch (key) {
            case "AbsentTypeDateID":
            case "Amount":
                const Unit = _.get(data, "data.Unit", _.get(dataForm.absentTypeDate[i], "Unit", ""));
                const absentTypeItemIDSelected = _.get(data, "data.AbsentTypeID", "");
                const absentTypeDateValue = key === "AbsentTypeDateID" ? absentTypeItemIDSelected : value;
                let validAbsType = [
                    ..._.slice(validation.absentTypeDate, 0, i),
                    {
                        ...validation.absentTypeDate[i],
                        [key]: absentTypeDateValue === "" ? requiredText : ""
                    },
                    ..._.slice(validation.absentTypeDate, i + 1)
                ];
                errorState = {
                    absentTypeDate: validAbsType
                }
                if (key === "AbsentTypeDateID") {
                    this.cboAbsentTypeIDFilter[i] = absentTypeItemIDSelected;
                    this.cboAbsentTypeIDFilter = _.uniq(this.cboAbsentTypeIDFilter);
                }
                value = [
                    ..._.slice(dataForm.absentTypeDate, 0, i),
                    {
                        ...dataForm.absentTypeDate[i],
                        [key]: absentTypeDateValue,
                        Unit,
                    },
                    ..._.slice(dataForm.absentTypeDate, i + 1)
                ];
                stateKey = "absentTypeDate";
                break;
            case "PeriodTime":
                this.loadCboHandOver({PeriodTime: value});
                break;
            case "Project":
                value = data.data;
                this.loadCboHandOver({ProjectID: _.get(value, "ProjectID", "")});
                break;
            case "Employee":
                value = data.data;
                break;
            default:
                break;
        }
        this.setState({
            validation: {
                ...validation,
                ...errorState,
            },
            dataForm: {
                ...dataForm,
                [stateKey]: value,
            }
        })
    }

    onChangeAttachments = (e) => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    onSave = async () => {
        const {mode, loadSetting} = this.props;
        const {dataForm, validation} = this.state;
        const dataResUploadFile = await this._uploadFile(this.attachments, true);
        const arrAttachments = this._getAttachments(dataResUploadFile);
        const dataFormPeriodTime = _.get(dataForm, "PeriodTime", "");
        const dataFormEmployee = _.get(dataForm, "Employee", []);
        const dataFormAbsentTypeDate = _.get(dataForm, "absentTypeDate", [])
        const isUserProject = _.get(loadSetting, "[0]IsUseProject", 0);
        const TransTime = _.split(dataFormPeriodTime, "/", 2);
        let method = "post";
        let params = {
            ..._.omit(dataForm, ['Employee', 'Project', "PeriodTime"]),
            DivisionID: Config.getDivisionID(),
            EmployeeID: _.get(dataFormEmployee, "EmployeeID", ""),
            ProjectID: _.get(dataForm, "Project.ProjectID", ""),
            TranMonth: TransTime[0] || "",
            TranYear: TransTime[1] || "",
            absentTypeDate: JSON.stringify(dataFormAbsentTypeDate),
            arrAttachment: JSON.stringify(arrAttachments),
            Type: _.get(this.props.data, "Type", null),
        };
        const arrInvalid = _.filter([{
            i: null,
            key: "Project",
            inValid: _.isEmpty(_.get(dataForm, "Project.ProjectID", "")),
        }, {
            i: null,
            key: "HandOverID",
            inValid: isUserProject ? _.isEmpty(_.get(dataForm, "HandOverID", "")) : false,
        }, {
            i: null,
            key: "Employee",
            inValid: _.isEmpty(dataFormEmployee),
        }, {
            i: null,
            key: "PeriodTime",
            inValid: _.isEmpty(_.get(dataForm, "PeriodTime", "")),
        }, ..._.reduce(dataFormAbsentTypeDate, (acc, item, i) => {
            acc = [...acc,
                {
                    i,
                    key: "AbsentTypeDateID",
                    inValid: _.isEmpty(item.AbsentTypeDateID)
                },
                {
                    i,
                    key: "Amount",
                    inValid: item.Amount === ""
                }
            ];
            return acc;
        }, [])
        ], "inValid");
        if (arrInvalid.length > 0) {
            let validAbsType = [...validation.absentTypeDate];
            const objInvalid = _.reduce(arrInvalid, (acc, item) => {
                let key = item.key;
                let val = "";
                if (key === "Amount" || key === "AbsentTypeDateID") {
                    validAbsType[item.i][item.key] = Config.lang("Truong_nay_bat_buoc_nhap");
                    val = validAbsType
                    key = "absentTypeDate"
                } else {
                    val = Config.lang("Truong_nay_bat_buoc_nhap")
                }
                acc[key] = val;
                return acc;
            }, {});
            this.setState({
                validation: {
                    ...validation,
                    ...objInvalid
                }
            })
            return false;
        }
        this.setState({loadingForm: true});
        if (mode === "edit") {
            method = "put";
            params = {...params, TransID: _.get(this.props.data, "TransID", ""), DivisionID: Config.getDivisionID()}
        }
        const res = await Api[method](`/w52f1001/${mode}`, params);
        this.setState({loadingForm: false});
        const messageErr = _.get(res, "message", "");
        const data = _.get(res, "data", "");
        const status = _.get(data, "Status", 1);
        const message = _.get(data, "Message", "");
        if (messageErr) {
            Config.popup.show('ERROR', messageErr);
            return false;
        }
        if (status || message) {
            Config.popup.show('INFO', message || Config.lang("Loi_chua_xac_dinh"));
            return false;
        }
        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
        this.onClose(true);
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = (file) => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const {dataOldAttachments} = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt : att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onClose = (isReload) => {
        const {onClose} = this.props;
        if (onClose) onClose(isReload);
    };

    onAddRow = () => {
        const {dataForm, validation} = this.state;
        this.setState({
            validation: {
                ...validation,
                absentTypeDate: [...validation.absentTypeDate, _.omit(emptyAbsentTypeDate, ["Unit"])]
            },
            dataForm: {
                ...dataForm,
                absentTypeDate: [
                    ...dataForm.absentTypeDate,
                    emptyAbsentTypeDate
                ]
            }
        });
    };

    onDeleteRow = i => {
        const {dataForm, validation} = this.state;
        this.cboAbsentTypeIDFilter.splice(i, 1);
        this.setState({
            validation: {
                ...validation,
                absentTypeDate: _.filter(validation.absentTypeDate, (_, ix) => ix !== i)
            },
            dataForm: {
                ...dataForm,
                absentTypeDate: _.filter(dataForm.absentTypeDate, (_, ix) => ix !== i)
            }
        });

    };

    componentDidMount() {
        const {mode} = this.props;
        if (mode === "edit" || mode === "view") {
            this.loadForm();
            this.loadAttachments();
        }
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadCboPeriods();
        this.loadCboAbsentType();
        this.loadSetting();
    }

    renderAbsentType = () => {
        const classes = useStyle();
        const {getCboAbsentType, mode} = this.props;
        const {dataForm: {absentTypeDate}, loadingForm, loadingCboAbsentType, validation} = this.state;
        const disabledAction = mode === "view";
        const dataCboAbsentType = _.filter(getCboAbsentType, itemCb => !_.includes(this.cboAbsentTypeIDFilter, itemCb.AbsentTypeID));
        return (<>
                    <Col xs={8} sm={8} md={8} lg={8}>
                        <label className={"MuiFormLabel-root"}>
                            {Config.lang("Sai_sot_cong")}
                            <span style={{paddingLeft: 4}}
                                  className={"MuiFormLabel-asterisk MuiInputLabel-asterisk"}>*</span>
                        </label>
                        <ButtonIcon
                            disabled={mode === "view" || dataCboAbsentType.length < 1}
                            color="primary"
                            className={"btn-add-w75f2010Popup"}
                            name="add"
                            size="small"
                            onClick={() => this.onAddRow()}
                            viewType="text"
                        />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <label className={"MuiFormLabel-root"}>
                            {Config.lang("So_luong")}
                            <span style={{paddingLeft: 4}}
                                  className={"MuiFormLabel-asterisk MuiInputLabel-asterisk"}>*</span>
                        </label>
                    </Col>
                {_.map(absentTypeDate, (item, i) => {
                    const unit = _.get(item, "Unit", "");
                    const idxCboAbsentType = this.getIndexDataCboAbsentType(item.AbsentTypeDateID);
                    let remainingDataCboAbsentType = [...dataCboAbsentType];
                    if (idxCboAbsentType !== -1) {
                        remainingDataCboAbsentType.unshift(getCboAbsentType[idxCboAbsentType])
                    }
                    return (
                        <React.Fragment key={i}>
                            <Col xs={8} className={"display_row"}>
                                <ButtonIcon
                                    disabled={absentTypeDate.length === 1 || disabledAction}
                                    name="delete"
                                    size="small"
                                    onClick={() => this.onDeleteRow(i)}
                                    viewType="text"
                                />
                                <Dropdown
                                    style={{ flex: 1 }}
                                    required={true}
                                    acceptCustomValue={true}
                                    error={validation["absentTypeDate"][i].AbsentTypeDateID}
                                    dataSource={remainingDataCboAbsentType}
                                    displayExpr={"{AbsentTypeID} - {AbsentTypeName}"}
                                    valueExpr={"AbsentTypeID"}
                                    disabled={loadingForm || loadingCboAbsentType || (mode === "view")}
                                    value={item.AbsentTypeDateID}
                                    onChange={e => this.handleChange("AbsentTypeDateID", e, i)}
                                />
                            </Col>
                            <Col xs={4}>
                                <NumberInput
                                    error={!_.isEmpty(validation["absentTypeDate"][i].Amount)}
                                    disabled={_.isEmpty(_.get(item, "AbsentTypeDateID", ""))}
                                    disabledNegative
                                    thousandSeparator
                                    decimalDigit={4}
                                    decimalSymbol={"."}
                                    value={item.Amount}
                                    onChange={e =>
                                        this.handleChange("Amount", {"value": e.target.value}, i)
                                    }
                                    endIcon={
                                        <Label className={classes.unit}>{unit}</Label>
                                    }
                                />
                            </Col>
                        </React.Fragment>
                    )
                })
                }
            </>
        );
    };

    render() {
        const {
            getCboPeriod,
            loadSetting,
            mode,
            data,
            open,
            onClose,
        } = this.props;
        const {
            dataCboEmployees,
            dataCboProjects,
            dataCboHandovers,
            loadingCboEmployee,
            loadingCboProject,
            loadingCboHandover,
            loadingCboPeriod,
            loadingAttachments,
            loadingForm,
            dataOldAttachments,
            validation,
            dataForm: {Employee, Project, PeriodTime, HandOverID, Notes}
        } = this.state;
        const isUserProject = _.get(loadSetting, "[0]IsUseProject", 0);
        const disabled = mode === "view";
        const disabledSave = Boolean(
            (_.get(data, "AppStatusID", 0) !== 0) ||
            !_.isEmpty(validation.Employee) ||
            !_.isEmpty(validation.PeriodTime) ||
            !_.every(validation.absentTypeDate, {AbsentTypeDateID: "", Amount: ""})
        )
        return (
            <Modal 
                open={open}
                onClose={onClose}
                style={{maxWidth:960}}
            >
                <ModalHeader>{Config.lang("Sai_sot_cong")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                className={'mgb0'}
                                required
                                error={validation["Employee"]}
                                disabled={loadingForm || disabled}
                                valueObjectDefault={typeof Employee === 'object' ? Employee : null}
                                dataSource={dataCboEmployees.rows}
                                displayExpr={"EmployeeName"}
                                valueExpr={"EmployeeID"}
                                renderSelectedItem={(data) => {
                                    const {data: item} = data;
                                    return (
                                        <div className={"display_row align-center"}>
                                            <Avatar
                                                readOnly
                                                width={24}
                                                height={24}
                                                data={item}
                                                src={`${Config.getUserPicture(item?.UserPictureURL)}`}
                                            />
                                            <Typography
                                                hoverTooltip
                                                className={"mgl5"}
                                                lineClamp={1}
                                            >{`${item?.EmployeeID} - ${item?.EmployeeName}`}</Typography>
                                        </div>
                                    );
                                }}
                                loading={loadingCboEmployee}
                                total={dataCboEmployees.total}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                value={Employee?.EmployeeID}
                                clearAble
                                iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                label={Config.lang("Nhan_vien")}
                                placeholder={Config.lang('Chon')}
                                onChange={(e) => this.handleChange("Employee", e)}
                                onInput={(e) => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <Dropdown
                                valueObjectDefault={typeof Project === 'object' ? Project : null}
                                required={true}
                                clearAble
                                error={validation["Project"]}
                                dataSource={dataCboProjects.rows}
                                skip={this.filterCboProjects.skip}
                                limit={this.filterCboProjects.limit}
                                displayExpr={'{ProjectID} - {ProjectName}'}
                                valueExpr={'ProjectID'}
                                value={Project?.ProjectID}
                                loading={loadingCboProject}
                                disabled={loadingForm || disabled}
                                readOnly={false}
                                label={Config.lang('Du_an')}
                                onChange={e => this.handleChange("Project", e)}
                                onInput={(e) => {
                                    this.filterCboProjects.strSearch = e.target.value;
                                    this.filterCboProjects.skip = 0;
                                    this.loadCboProjects(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboProjects.skip = e.skip;
                                    this.filterCboProjects.limit = e.limit;
                                    this.loadCboProjects();
                                }}
                            />
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Dropdown
                                error={validation["PeriodTime"]}
                                loading={loadingCboPeriod}
                                disabled={loadingForm || disabled}
                                dataSource={getCboPeriod}
                                valueExpr={"PeriodTime"}
                                displayExpr={"PeriodTime"}
                                viewType={"underlined"}
                                value={PeriodTime}
                                required={true}
                                label={Config.lang("Ky_luong")}
                                onChange={(e) => this.handleChange("PeriodTime", e)}
                            />
                        </Col>
                    {isUserProject ? 
                        <Col xs={12}>
                                <Dropdown
                                    error={validation["HandOverID"]}
                                    required={true}
                                    dataSource={dataCboHandovers}
                                    displayExpr={"HandOverName"}
                                    valueExpr={"HandOverID"}
                                    value={HandOverID}
                                    loading={loadingCboHandover}
                                    viewType={"underlined"}
                                    disabled={loadingCboHandover || !Boolean(_.get(Project, "ProjectID", false) && PeriodTime) || disabled}
                                    label={Config.lang("Ban_giao")}
                                    onChange={(e) => this.handleChange("HandOverID", e)}
                                />
                        </Col>
                    : ""}
                    {this.renderAbsentType()}
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Config.lang("Ghi_chu")}
                                viewType={"underlined"}
                                value={Notes}
                                disabled={loadingForm || disabled}
                                onChange={e => this.handleChange("Notes", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                disabled={loadingAttachments || disabled}
                                maxFile={5}
                                multiple
                                data={dataOldAttachments}
                                onChange={this.onChangeAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                    maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                    maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{justifyContent: "space-between"}}>
                    <ButtonGeneral
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        disabled={
                            loadingForm || disabled || disabledSave
                        }
                        style={{textTransform: "uppercase"}}
                        size={"large"}
                        onClick={this.onSave}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

W52F1001.propTypes = {
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboPeriod: state.W75F2010.getCboPeriod,
            getCboAbsentType: state.W75F2010.getCboAbsentType,
            loadSetting: state.general.loadSetting,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch),
            w29f2006Actions: bindActionCreators(W29F2006Actions, dispatch),
        })
    ),
)(W52F1001);
