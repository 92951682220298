import React, {Component} from 'react';
import {Col, FormGroup, Row} from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import Approvals from "../../../approvals/approvals";
import _ from "lodash";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as w09f2060 from "../../../../redux/W0X/W09F2060/W09F2060_actions";
import withStyles from "@material-ui/core/styles/withStyles";
import Filter from "../../../filter/filter";
import {FormControl, FormLabel as Label, InputLabel} from "@material-ui/core";
import DateBoxPicker from "../../../common/form-material/date-box";
import {Combo} from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import LabelText from "../../../common/label-text/label-text";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

const styles = () => ({
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    },
})

class W52F1010 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            loading: false,
            loadingCboProject: false,
            loadingCboEmployee: false,
            dataForm: {},
            dataCboEmployees: {rows: [], total: 0},
            dataCboProjects: {rows: [], total: 0},
            DateFrom: null,
            DateTo: null,
            VoucherID: "",
            dataFilter: {
                DateFrom: this?.state?.DateFrom ?? null,
                DateTo: this?.state?.DateTo ?? null,
                ProjectID: this.filter?.Project?.ProjectID ?? "",
                EmployeeID: this.filter?.Employee?.EmployeeID ?? "",
                DepartmentID: this.filter?.DepartmentID ?? "",
                ApprovalStatus: this.filter?.ApprovalStatus ?? "",
                searchvalue: this.filter?.searchvalue ?? "",
            }
        };
        this.filter = {
            Project: "",
            Employee: "",
            DepartmentID: "",
            ApprovalStatus: "",
            searchvalue: "",
        };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployee = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W52F1010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W52F1010",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({loadingCboEmployee: false});
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W521010",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.setState({loadingCboProject: true})
        this.props.generalActions.getCboProjects(params, (err, data) => {
            this.setState({loadingCboProject: false})
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const {DateFrom, DateTo} = this.state;
        const {Employee, Project} = this.filter;
        const params = {
            FormID: "W52F1010",
            Language: Config.language || '84',
            DivisionID: Config.getDivisionID(),
            ..._.omit(this.filter, ["Employee", "Project"]),
            EmployeeID: _.get(Employee, "EmployeeID", ""),
            ProjectID: _.get(Project, "ProjectID", ""),
            VoucherID,
            DateFrom,
            DateTo,
        };
        this.setState({loading: true});
        this.props.approvalActions.getGridApprovals(params, (err) => {
            this.setState({loading: false});
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    loadFormInfo = (VoucherID = "", cb) => {
        const params = {
            FormID: "W52F1010",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84",
            VoucherID,
        };
        this.props.approvalActions.getFormInfo(params, (err, data) => {
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb(data);
            }
        });
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getGridApproval} = this.props;
        if (this.refs && this.refs['Approvals']) {
            Config.callChildForm({
                FormID:   "W52F1010",
                // ID: voucher_id,
                keyExpr:  "VoucherID",
                data:     getGridApproval && getGridApproval.rows ? getGridApproval.rows : [],
                onLoad:   (params) => this.loadGrid(params && params.ID),
                onAction: (e, data) => {
                    const {ID} = e || "";
                    this.refs['Approvals'].showPopup(data);
                    this.loadFormInfo(ID);
                }
            }, this.props);
        }
    }

    handleFilter = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        const arrDataField = ["DateFrom", "DateTo"];
        if (arrDataField.includes(key)) {
            this.setState({
                [key]: value
            });
            return;
        }
        this.filter[key] = value;
    }

    onOpenFilter = () => {
        this.loadCboProjects();
        this.loadCboEmployees();
    }

    onSearch = () => {
        const {DateFrom, DateTo} = this.state;
        const { Employee, Project, DepartmentID, ApprovalStatus, searchvalue } = this.filter;
        this.setState({dataFilter: {
            DateFrom,
            DateTo,
            EmployeeID: _.get(Employee, "EmployeeID", ""),
            ProjectID: _.get(Project, "ProjectID", ""),
            DepartmentID,
            ApprovalStatus,
            searchvalue,
        }});
    }

    renderFilter = () => {
        const {getCboStatus} = this.props;
        const {dataCboEmployees, dataCboProjects, DateTo, DateFrom, loadingCboProject, loadingCboEmployee} = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                onOpened={this.onOpenFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={this.filter.ApprovalStatus}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilter("ApprovalStatus", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={this.filter.Employee}
                                            loading={loadingCboEmployee}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Nhan_vien")}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("Employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={this.filter.Project}
                                            loading={loadingCboProject}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Du_an')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.handleFilter("Project", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                max={DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={(e) => this.handleFilter("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={(e) => this.handleFilter("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{textTransform: "uppercase"}}
                                    size={"large"}
                                    onClick={this.onSearch}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        )
            ;
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    renderFormInfo = (data) => {
        const {classes} = this.props;
        let dataAbsentType = _.get(data, "AbsentTypeDate", []);
        if (dataAbsentType.length < 1) {
            dataAbsentType = [{AbsentTypeDateName: "", Amount: "", Unit: ""}]
        }
        return (
            <>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Nhan_vien")}
                        allowPadding={true}
                        value={() => {
                            return (
                                <div className={"display_row align-center pdb5"} style={{width: "100%", height: 37}}>
                                    <div className={classes.divAvatar}>
                                        <UserImage width={32} height={32} data={data}/>
                                    </div>
                                    <UserName allowHover={false} data={data}/>
                                </div>
                            );
                        }}
                        fullWidth={true}
                    />
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Du_an")}
                                value={Config.helpers.getObjectValue(data, 'ProjectName', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ky_luong")}
                                value={Config.helpers.getObjectValue(data, 'Period', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ban_giao")}
                                value={_.get(data, 'HandOverName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <FormControl fullWidth={true}>
                                <InputLabel shrink={true} focused={false} style={{fontSize: '14px', fontWeight: 200}}>
                                    {Config.lang("DHR_Loai_thu_nhap")}
                                </InputLabel>
                                {_.map(dataAbsentType, (item, indx) => {
                                    return (
                                        <Row key={indx} style={indx === 0 ? {marginTop: 20} : null}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <LabelText
                                                    allowPadding={true}
                                                    value={_.get(item, 'AbsentTypeDateName', '')}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </FormControl>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <FormControl fullWidth={true}>
                                <InputLabel shrink={true} focused={false} style={{fontSize: '14px', fontWeight: 200}}>
                                    {Config.lang("DHR_So_luong")}
                                </InputLabel>
                                {_.map(dataAbsentType, (item, indx) => {
                                    return (
                                        <Row key={indx} style={indx === 0 ? {marginTop: 20} : null}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <LabelText
                                                    allowPadding={true}
                                                    value={_.toString(_.get(item, 'Amount', ''))}
                                                    fullWidth={true}
                                                />
                                                <div style={{position: "absolute", right: 15, top: 11}}>
                                                    <label
                                                        className={"MuiFormLabel-root"}>{_.get(item, "Unit", "")}</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </FormControl>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LabelText
                        allowPadding={true}
                        label={Config.lang("DHR_Ghi_chu")}
                        value={_.get(data, 'Notes', '')}
                        fullWidth={true}
                    />
                </FormGroup>
            </>
        );
    };

    render() {
        const {getGridApproval} = this.props;
        const {iPermission, loading, dataForm, VoucherID, dataFilter} = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_sai_sot_cong")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                FormID={"W52F1010"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                dataSource={getGridApproval}
                                loading={loading}
                                selectedRowKey={VoucherID}
                                singleClick
                                allowHistoryApproval
                                dataFilter={dataFilter}
                                filterRender={() => this.renderFilter()}
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

    componentDidMount = async () => {
        const {voucher_id} = Config.getUrlParams(this.props);
        await this.loadPermission();
        if (!this.state.iPermission) return;
        if (!voucher_id) {
            this.loadGrid();
        }
    }
}

export default compose(connect((state) => ({
    getCboStatus: state.approvals.getCboStatus,
    getGridApproval: state.approvals.getGridApproval,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
    w09f2060Actions: bindActionCreators(w09f2060, dispatch),
})), withStyles(styles, {withTheme: true}))(W52F1010);
