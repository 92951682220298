/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 23/12/2019
 * @Example
 */

import { Column } from "devextreme-react/data-grid";
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import Slider from "react-slick";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W75F2005Actions from "../../../../redux/W75/W75F2005/W75F2005_actions";
import FileViewer from "../../../common/fileviewer/fileviewer";
import Modal from "../../../common/modal/modal";
import Status from "../../../common/status/status";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W75F2000Popup from "../W75F2000/W75F2000Popup";
import W75F2008 from "../W75F2008/W75F2008";
import PopoverW752005 from "./W75F2005Popover";
import { Sheet }              from 'diginet-core-ui/icons';
import {
    Button,
    ButtonIcon,
    Chip,
    DatePicker,
    Dropdown,
    FormGroup,
    Label,
    Col,
    Row,
} from "diginet-core-ui/components";

const useStyles = makeStyles({
    itemAtt: {
        borderRadius: 100,
        padding: '0 8px',
        border: '1px solid lightgrey',
        fontWeight: '400',
        marginRight: 10,
        fontSize: 12,
        height: 24,
        lineHeight: '22px',
        display: 'block',
        marginBottom: 10,
        '&:hover': {
            backgroundColor: '#E4E9F2'
        }
    }
});

const styles = {
    slick: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        position: 'absolute',
        top: "50%",
        zIndex: 2,
    }
};

const NextArrow = props => {
    const { onClick } = props;
    return (
        <ButtonIcon
            circular
            style={{ ...styles.slick, left: "93.5%" }}
            name={"ArrowRight"}
            size={"medium"}
            viewType={"text"}
            onClick={onClick}
        />
    );
};

const PrevArrow = props => {
    const { onClick } = props;
    return (
        <ButtonIcon
            circular
            // style={{ ...styles.slick, left: "2%" }}
            name={"ArrowLeft"}
            size={"medium"}
            viewType={"text"}
            onClick={onClick}
        />
    );
};

class W75F2005 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            anchorEl: undefined,
            type: "",
            showW75F2005Popup: false,
            dataPopup: null,
            dataPopover: null,
            gridLoading: true,
            dataGrid: [],
            total: 0,
            CreateDateFrom: null,
            CreateDateTo: null,
            LeaveDateFrom: null,
            LeaveDateTo: null,
            createUserLoading: false,
            employeeLoading: false,
            showFileViewer: null,
            slideIndex: 0,
            CreateUserID: "",
            EmployeeID: "",
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
            dataCboCreateUsers: {
                total: 0,
                rows: []
            },
            dataCboEmployees: {
                total: 0,
                rows: []
            },
            openModalW75F2008: false
        };

        this.filter = {
            skip: 0,
            limit: 10
        };
        this.filterCboCreateUsers = {
            selectedData: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 20
        };
        this.slider = null;
        this.refPopover = null;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F2005", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || '84'
        };
        this.props.w75F2000Actions.getLeaveTypes(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboCreateUsers = (isReset) => {
        const { dataCboCreateUsers } = this.state;
        const params = {
            HostID: "",
            Type: "CreateUserID",
            FormID: "W75F2005",
            Language: Config.language || '84',
            skip: this.filterCboCreateUsers.skip,
            limit: this.filterCboCreateUsers.limit,
            search: this.filterCboCreateUsers.strSearch
        };
        this.setState({ createUserLoading: true });
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({ createUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                this.setState({
                    dataCboCreateUsers: {
                        rows: isReset ? rows : dataCboCreateUsers.rows.concat(rows),
                        total
                    }
                });
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const { dataCboEmployees } = this.state;
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2005",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                });
            }
        });
    };

    getInfo = () => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                EmployeeID: location.state.EmployeeID
            }
        } else {
            return {
                EmployeeID: Config.getHREmployeeID()
            }
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadGrid();

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        Config.callChildForm({
            FormID: "W75F2005",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: this.state.dataGrid,
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => this.onView(null, data)
        }, this.props);
    }

    loadGrid = (VoucherID) => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const { skip, limit } = this.filter;
        const { CreateDateFrom, CreateDateTo, LeaveDateFrom, LeaveDateTo, EmployeeID, AppStatusID, LeaveTypeID } = this.state;
        let params = {
            Language: Config.language || "84",
            CreateDateFrom,
            CreateDateTo,
            LeaveDateFrom,
            LeaveDateTo,
            CreateUserID: this.filterCboCreateUsers.selectedData?.UserID ?? "",
            EmployeeID,
            LeaveTypeID,
            VoucherDate: "",
            ProjectID: "",
            OrgchartID: "",
            HostID: "",
            FormID: "W75F2005",
            TransID: VoucherID || "",
            skip: skip.toString(),
            limit: limit.toString(),
        };
        if (Number.isInteger(AppStatusID)) {
            params.AppStatusID = AppStatusID.toString();
        }
        this.setState({ gridLoading: true });
        this.props.W75F2005Actions.loadGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ gridLoading: false });
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                total: data && data.total ? data.total : 0,
            }, () => {
                this.setState({ gridLoading: false });
            });
        });
    };

    empProfile = (data) => {
        const EmployeeID = data.EmployeeID;

        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: EmployeeID }
        })
    };

    renderItem = (e) => {
        const classes = useStyles();
        const { data } = e.row;

        const renderAmoutRegistBefore = (vl) => {
            let amout = Math.abs(vl || 0);
            if (amout > 1 && Config.getLocale() === 'en') amout += ` ${Config.lang('Ngay3')}s`;
            else amout += ` ${Config.lang('Ngay3')}`;
            return amout;
        };

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <Link style={{ fontSize: '1.12rem', fontWeight: 500, color: "#707070" }} onClick={() => this.empProfile(data)} onlyActiveOnIndex><UserName data={data} renderItem={() => (data.EmployeeID + " - " + data.EmployeeName)} /></Link>
                    <div style={{ fontWeight: 200 }}>
                        {data.Quantity} {data.LeaveTypeName} - {data.LeaveTypeID} - {data.LeaveDateFrom}{data.LeaveDateTo ? " - " + data.LeaveDateTo : ""}
                    </div>
                    {_.isNumber(data.AmoutRegistBefore) &&
                        <div style={{ fontWeight: 200 }}>
                            <span>
                                {_.join(
                                    [
                                        data.AmoutRegistBefore < 0 ? Config.lang("Dang_ky_sau") : Config.lang("Dang_ky_truoc_d"),
                                        renderAmoutRegistBefore(data.AmoutRegistBefore)
                                    ], ": "
                                )}
                            </span>
                        </div>
                    }
                    {(data.AppStatusID !== '0' && data.AppStatusID !== 0) &&
                        <div style={{ fontWeight: 200, whiteSpace: "pre-wrap", width: "80%" }}>{Config.lang("Ghi_chu")}:
                            <span> {data.Notes}</span>
                        </div>
                    }
                    {data.arrAttachment &&
                        <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexWrap: 'wrap' }}>
                            {data.arrAttachment.map((item, idx) => {
                                if (idx > 2) return null;
                                return (
                                    <span onClick={() => { this.setState({ showFileViewer: data, slideIndex: idx }) }} key={idx} className={classes.itemAtt}>{item.FileName}</span>
                                )
                            })}
                            {data.arrAttachment.length > 3 &&
                                <span
                                    onClick={() => { this.setState({ showFileViewer: data, slideIndex: data.arrAttachment.length - 2 }) }}
                                    className={classes.itemAtt}
                                    style={{ display: 'inline-flex', textAlign: 'center', width: "auto", padding: 5, justifyContent: "center", alignItems: "center" }}>
                                    +{parseInt(data.arrAttachment.length) - 3}
                                </span>
                            }
                        </div>
                    }
                    {Boolean(data.IsRegisterType) && <>
                        {data.LeaveCancelReason &&
                            <div>{Config.lang("Ly_do_huy_phep")}: {data.LeaveCancelReason || ""}</div>}
                        <Chip color={"primary"} label={Config.lang("Huy_phep")} viewType={"filled"} />
                    </>}
                </div>
            </div>
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        return <Status data={data} displayExpr={"AppStatus"} />;
    };
    onHistoryApproval = (e, data = {}) => {
        this.setState({ w84F3005PopupData: { TransID: data.TransID }, showW84F3005Popup: true })
    };
    onOpenCheck = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("INFO", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"))
        }
        else {
            this.setState({
                anchorEl: e.currentTarget,
                type: "check",
                dataPopover: data
            }, () => {
                if (this.refPopover) {
                    this.refPopover.onCheck()
                }
            });
        }
    };

    onOpenClose = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("INFO", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"))
        }
        else {
            this.setState({
                anchorEl: e.currentTarget,
                type: "close",
                dataPopover: data
            }, () => {
                if (this.refPopover) {
                    this.refPopover.onCheck()
                }
            });
        }
    };

    onView = (e, data) => {
        this.setState({
            showW75F2005Popup: true,
            dataPopup: data
        })
    };

    onCloseModal = (isReload = false) => {
        if (isReload) this.loadGrid();
        this.setState({
            showW75F2005Popup: false,
        })
    };

    renderAction = (e) => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"history"}
                    className={"mgr5"}
                    onClick={() => this.onHistoryApproval(e, data)}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"Approval"}
                    className={"mgr5"}
                    colorHover={Config.coreTheme?.colors?.semantic.success}
                    onClick={(e) => this.onOpenCheck(e, data)}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"Cancel"}
                    className={"mgr5"}
                    colorHover={Config.coreTheme?.colors?.semantic.danger}
                    onClick={(e) => this.onOpenClose(e, data)}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"View"}
                    className={"mgr5"}
                    onClick={(e) => this.onView(e, data)}
                />
            </div>
        )
    };

    filterChange = (key, e) => {
        if (!key || !e) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        const stateFieldName = ['CreateDateFrom', 'CreateDateTo', 'LeaveDateFrom', 'LeaveDateTo', 'CreateUserID', 'EmployeeID', 'AppStatusID', 'LeaveTypeID'];
        if (key === 'CreateUserID') {
            this.filterCboCreateUsers.selectedData = e.data;
        }
        if (stateFieldName.includes(key)) {
            this.setState({ [key]: value });
        } else {
            this.filter[key] = value;
        }
    };

    onLoadDataFilter = () => {
        this.loadCboCreateUsers();
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.loadCboLeaveTypes();
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W75F2005",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || '84'
        };
        this.props.W75F2005Actions.getLeaveTypes(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    renderFilters = () => {
        const { CreateUserID, EmployeeID, createUserLoading, employeeLoading, CreateDateFrom, CreateDateTo, LeaveDateFrom, LeaveDateTo, dataCboCreateUsers, dataCboEmployees, AppStatusID, LeaveTypeID } = this.state;
        const { getCboAppStatus, getLeaveTypes } = this.props;
        return (
            <Filter
                isUseDDCore={true}
                onTextChanged={this.onSearch}
                placeholder={Config.lang("Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12}>
                                    <Label>{Config.lang("Ngay_de_xuat")}</Label>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className={"display_row align-center"}>
                                        <Label style={{ padding: '0px 5px 15px', wordBreak: 'unset' }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={CreateDateTo}
                                            value={CreateDateFrom}
                                            onChange={e => this.filterChange("CreateDateFrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className={"display_row align-center"}>
                                        <Label style={{ padding: '0px 5px 15px', wordBreak: 'unset' }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            min={CreateDateFrom}
                                            value={CreateDateTo}
                                            onChange={e => this.filterChange("CreateDateTo", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Label>{Config.lang("Ngay_nghi_phep")}</Label>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className={"display_row align-center"}>
                                        <Label style={{ padding: '0px 5px 15px', wordBreak: 'unset' }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={LeaveDateTo}
                                            value={LeaveDateFrom}
                                            onChange={e => this.filterChange("LeaveDateFrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className={"display_row align-center"}>
                                        <Label style={{ padding: '0px 5px 15px', wordBreak: 'unset' }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            min={LeaveDateFrom}
                                            value={LeaveDateTo}
                                            onChange={e => this.filterChange("LeaveDateTo", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        allowSearch
                                        clearAble
                                        dataSource={_.get(dataCboCreateUsers, "rows", [])}
                                        total={_.get(dataCboCreateUsers, "total", 0)}
                                        skip={this.filterCboCreateUsers.skip}
                                        limit={this.filterCboCreateUsers.limit}
                                        loading={createUserLoading}
                                        value={CreateUserID}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        renderSelectedItem={"EmployeeName"}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        label={Config.lang("Nguoi_lap")}
                                        placeholder={Config.lang("Chon")}
                                        onChange={e => this.filterChange("CreateUserID", e)}
                                        searchDelayTime
                                        onInput={(e) => {
                                            this.filterCboCreateUsers.strSearch = e.target.value;
                                            this.filterCboCreateUsers.skip = 0;
                                            this.filterCboCreateUsers.limit = 20;
                                            this.loadCboCreateUsers(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboCreateUsers.skip = e.skip;
                                            this.filterCboCreateUsers.limit = e.limit;
                                            this.loadCboCreateUsers();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} >
                                    <Dropdown
                                        allowSearch
                                        clearAble
                                        dataSource={_.get(dataCboEmployees, "rows", [])}
                                        total={_.get(dataCboEmployees, "total", 0)}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        loading={employeeLoading}
                                        value={EmployeeID}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        renderSelectedItem={"EmployeeName"}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        label={Config.lang("Nhan_vien")}
                                        placeholder={Config.lang("Chon")}
                                        onChange={e => this.filterChange("EmployeeID", e)}
                                        onInput={(e) => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.filterCboEmployees.limit = 20;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"AppStatusID"}
                                        displayExpr={"AppStatusName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Trang_thai_duyet")}
                                        onChange={e => this.filterChange("AppStatusID", e)}
                                        value={AppStatusID}
                                        dataSource={getCboAppStatus}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"LeaveTypeID"}
                                        displayExpr={"LeaveTypeName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Loai_phep")}
                                        onChange={e => this.filterChange("LeaveTypeID", e)}
                                        value={LeaveTypeID}
                                        dataSource={getLeaveTypes}
                                    />
                                </Col>
                                <Col xs={12} className={"display_row valign-bottom"}>
                                    <Button
                                        className={"mgt15"}
                                        size={"medium"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        startIcon={"Search"}
                                        label={Config.lang("Tim_kiem")}
                                        onClick={() => this.loadGrid()}
                                    />
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        );
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    onOpenModalW75F2008 = (status = false) => {
        if (_.isBoolean(status)) this.setState({ openModalW75F2008: status });
    };

    onExportExcel = () => {
        const { skip, limit } = this.filter;
        const { CreateDateFrom, CreateDateTo, LeaveDateFrom, LeaveDateTo, EmployeeID, AppStatusID, LeaveTypeID } = this.state;
        let params = {
            TransID: "",
            EmployeeID,
            AppStatusID: Number.isInteger(AppStatusID) ? AppStatusID.toString() : (AppStatusID ?? ""),
            skip: skip.toString(),
            limit: limit.toString(),
            LeaveTypeID,
            CreateUserID: this.filterCboCreateUsers.selectedData?.UserID ?? "",
            CreateDateFrom,
            CreateDateTo,
            LeaveDateFrom,
            LeaveDateTo,
        };
        this.props.W75F2005Actions.exportExcel(params, (error, dataFile) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const link = document.createElement("a");
            link.href = dataFile?.URL ?? "";
            link.download = dataFile.fileName ? decodeURI(dataFile.fileName) : "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            Config.notify.show("success", Config.lang("Xuat_du_lieu_thanh_cong"), 3000);
        });
    };


    render() {
        const { iPermission, anchorEl, type, showW75F2005Popup, dataPopup, dataPopover, gridLoading, dataGrid, total, showFileViewer,
            showW84F3005Popup, w84F3005PopupData = {}, openModalW75F2008 } = this.state;
        if (!iPermission) return null;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };
        return (
            <React.Fragment>
                <W84F3005 open={showW84F3005Popup} onClose={() => this.setState({ showW84F3005Popup: false })} FormID="W75F2005" TransID={w84F3005PopupData.TransID} />

                {showW75F2005Popup && <W75F2000Popup open={showW75F2005Popup} fromW75F2005 mode={"view"} data={dataPopup}  onClose={this.onCloseModal} />}

                <PopoverW752005
                    ref={ref => this.refPopover = ref}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    setLoading={isLoading => this.setState({ gridLoading: isLoading })}
                    funcLoadGrid={this.loadGrid}
                />
                {openModalW75F2008 &&
                    <W75F2008 openModal={openModalW75F2008}
                        onOpenModal={(status, reload) => {
                            if (_.isBoolean) this.setState({ openModalW75F2008: status });
                            if (reload === true) this.loadGrid(); // Load lại lưới
                        }}
                    />

                }
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar title={Config.lang("Duyet_nghi_phep")} >
                        <Button
                            disabled={!(iPermission > 1) || gridLoading}
                            size={"medium"}
                            color={"primary"}
                            viewType={"filled"}
                            label={Config.lang("Duyet_hang_loat")}
                            onClick={() => this.onOpenModalW75F2008(true)}
                        />
                        <Button
                            disabled={!(iPermission > 0) || gridLoading}
                            startIcon={
                                <Sheet color={Config.coreTheme?.colors?.success} width={24} height={24}/>
                            }
                            color={"primary"}
                            label={Config.lang('Xuat_excel')}
                            className={"mgl4x"}
                            viewType={'outlined'}
                            onClick={this.onExportExcel}
                        />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: 'none' }}
                                dataSource={dataGrid}
                                keyExpr={"TransID"}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid() - 15}
                                typePaging={"remote"}
                                loading={gridLoading}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    cellRender={({ row }) => {
                                        const { data: { CreateUserID } = {} } = row;
                                        const user = Config.getUser({ UserID: CreateUserID });
                                        return <UserImage user={user} />
                                    }}
                                />
                                <Column
                                    dataField={"EmployeeName"}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    alignment={"center"}
                                    dataField={'AppStatus'}
                                    cellRender={this.renderStatus}
                                    minWidth={150}
                                    maxWidth={200}
                                />
                                <Column
                                    cellRender={this.renderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                    {showFileViewer && <Modal className={"modal-review-file"} style={{ zIndex: 1504 }}
                        open={!!showFileViewer}
                        maxWidth={"lg"}
                        fullWidth={true}
                        paperProps={{ style: { height: '100%' } }}
                        title={Config.lang('Xem_tap_tin')}
                        onClose={() => { this.setState({ showFileViewer: null }) }}>
                        <Slider initialSlide={this.state.slideIndex} {...settings}>
                            {showFileViewer.arrAttachment.map((file, idx) => {
                                const FileName = file && file.FileName ? file.FileName : "";
                                const FileExt = file && file.fileExt ? file.fileExt : Config.helpers.getExtFile(file ? file.FileName : '');
                                const URL = file && file.URL ? (file.URL.indexOf('http') < 0 ? Config.getCDNPath() + file.URL : file.URL) : "";
                                return (
                                    <FileViewer
                                        key={idx}
                                        fileName={FileName}
                                        filePath={URL}
                                        fileType={FileExt}
                                        height={790} />
                                )
                            })}
                        </Slider>
                    </Modal>}
                </FormGroup>
            </React.Fragment >
        );
    }
}

export default compose(connect((state) => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getLeaveTypes: state.W75F2005.getLeaveTypes,
    loadGrid: state.W75F2005.loadGrid
}),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W75F2005Actions: bindActionCreators(W75F2005Actions, dispatch),
    })
))(W75F2005);
