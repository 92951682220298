/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 07/01/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import Filter from "../../../filter/filter";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions";
import moment from "moment";
import { browserHistory } from "react-router";
import W75F2010Popup from "./W75F2010Popup";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import {
    ButtonIcon,
    Dropdown,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    DateRangePicker,
    Typography,
} from "diginet-core-ui/components";
import { CalendarNew } from "diginet-core-ui/icons";

class W75F2010 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            showW75F2010Popup: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            loading: {
                cboEmployees: false,
                cboProjects: false,
            },
            PeriodTime: "",
            Project: null,
            AppStatusID: "",
            Employee: null,
            RangePicker: [],
        };
        this.loading = {
            cboEmployees: false,
            cboProjects: false,
        };
        this.filter = {
            VoucherDate: "",
            EmployeeID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboProject = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F2010", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID },
        });
    };

    renderEmpProfile = e => {
        const { data } = e.row;
        const date = Config.convertDate(data.EntryDate, "", "LT, DD/MM/YYYY", true);

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)} />
                <div className={'mgl4x'}>
                    <Typography type={"h3"}>
                        <UserName data={data} />
                    </Typography>
                    <div className={"display_row align-center"}>
                        <CalendarNew height={14} viewBox width={14} />
                        <div className={"pdl2x"}>
                            <Typography type={"p2"}> {data.EntryDate && date}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const date = moment(data.ApprovalDate).format("DD/MM/YYYY");
        return (
            <div className={"pdt10 pdb10"}>
                <Typography type={"p2"}>
                    {Config.lang("Ky_luong")}: {data.Period}
                </Typography>
                <Typography type={"p2"}>{data.Note}</Typography>
                <Typography type={"p2"}>
                    {Config.lang("Nguoi_lap")}: {data.Proposer}
                </Typography>
                {data.ApproverName && (
                    <Typography type={"p2"}>
                        {Config.lang("Nguoi_duyet")}: {data.ApproverName} - {data.DepartmentID} - {date}
                    </Typography>
                )}
                {data.AppStatusID > 0 && (
                    <Typography type={"p2"}>
                        {Config.lang("Ghi_chu_duyet")}: {data.ApprovalNotes}
                    </Typography>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { data } = e.row;
        return <Status data={data} />;
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            showW75F2010Popup: true,
        });
    };

    onView = e => {
        const { data } = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW75F2010Popup: true,
        });
    };

    onEdit = ({ data }) => {
        this.setState({
            mode: "edit",
            rowData: data,
            showW75F2010Popup: true,
        });
    };

    onDelete = data => {
        const { AbsentVoucherID, EmployeeID } = data;

        const param = {
            FormID: "W75F2010",
            EmployeeID: EmployeeID,
            TransID: AbsentVoucherID,
        };

        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            this.props.W75F2010Actions.deleteDataGrid(param, error => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                    this.loadDataGrid();
                }
            });
        });
    };

    renderAction = e => {
        const { data } = e.row;

        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    viewType={"text"}
                    circular
                    onClick={() => this.onView(e)}
                    name={"View"}
                    className={"mgr5"}
                />
                <ButtonIcon
                    viewType={"text"}
                    circular
                    disabled={data && Number(data.AppStatusID) !== 0}
                    onClick={() => this.onDelete(data)}
                    name={"delete"}
                />
            </div>
        );
    };

    filterChange = (key, data) => {
        const { value } = data;
        if (!key) return false;
        if (key === "RangePicker") {
            const dateFrom = Array.isArray(value) ? value[0] : value;
            const dateTo = Array.isArray(value) ? value[1] : value;
            this.setState({ [key]: [dateFrom, dateTo] });
        } else {
            this.setState({ [key]: value });
        }
    };

    renderFilter = () => {
        const { getCboPeriod, getCboAppStatus } = this.props;
        const { dataCboEmployees, dataCboProjects, loading, PeriodTime, Project, AppStatusID, Employee, RangePicker } =
            this.state;

        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                isUseDDCore
                renderFilter={() => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        viewType={"outlined"}
                                        placeholder={Config.lang("Ky_luong")}
                                        dataSource={getCboPeriod}
                                        displayExpr={"PeriodTime"}
                                        valueExpr={"PeriodTime"}
                                        onChange={e => this.filterChange("PeriodTime", e)}
                                        clearAble
                                        value={PeriodTime}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <DateRangePicker
                                        clearAble
                                        controls
                                        value={RangePicker}
                                        onChange={e => this.filterChange("RangePicker", e)}
                                        placeholder={`${Config.lang("Tu_ngay")} - ${Config.lang("Den_ngay")}`}
                                        returnFormat={"YYYY-MM-DD"}
                                        viewType={"outlined"}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        viewType={"outlined"}
                                        placeholder={Config.lang("Trang_thai_duyet")}
                                        dataSource={getCboAppStatus}
                                        displayExpr={"AppStatusName"}
                                        valueExpr={"ApprovalStatus"}
                                        onChange={e => this.filterChange("AppStatusID", e)}
                                        clearAble
                                        value={AppStatusID}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        allowSearch
                                        dataSource={dataCboEmployees.rows}
                                        total={dataCboEmployees.total}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        valueExpr={"EmployeeID"}
                                        value={Employee}
                                        loading={loading.cboEmployees}
                                        viewType={"outlined"}
                                        clearAble
                                        placeholder={Config.lang("Nhan_vien")}
                                        renderSelectedItem={"EmployeeName"}
                                        onChange={e => this.filterChange("Employee", e)}
                                        onInput={e => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={e => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        allowSearch
                                        dataSource={dataCboProjects.rows}
                                        total={dataCboProjects.total}
                                        skip={this.filterCboProject.skip}
                                        limit={this.filterCboProject.limit}
                                        displayExpr={"{ProjectID} - {ProjectName}"}
                                        valueExpr={"ProjectID"}
                                        value={Project}
                                        loading={loading.cboProjects}
                                        viewType={"outlined"}
                                        clearAble
                                        placeholder={Config.lang("Du_an")}
                                        renderSelectedItem={"ProjectName"}
                                        onChange={e => this.filterChange("Project", e)}
                                        onInput={e => {
                                            this.filterCboProject.strSearch = e.target.value;
                                            this.filterCboProject.skip = 0;
                                            this.loadCboProjects(true);
                                        }}
                                        onLoadMore={e => {
                                            this.filterCboProject.skip = e.skip;
                                            this.filterCboProject.limit = e.limit;
                                            this.loadCboProjects();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <div className={"display_row valign-middle mgt4x"}>
                                <Button
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"search"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            />
        );
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDataGrid();
        this.loadCboPeriod();
        this.loadCboAppStatus();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getDataGrid } = this.props;
        Config.callChildForm(
            {
                FormID: "W75F2010",
                // ID: voucher_id,
                keyExpr: "AbsentVoucherID",
                data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
                onLoad: params => this.loadDataGrid(params && params.ID),
                onAction: (e, data) => this.onView({ row: { data: data } }),
            },
            this.props
        );
    }

    // UNSAFE_componentWillReceiveProps = (nextProps) => {
    //     const AbsentVoucherID1 = this.getInfo(nextProps).AbsentVoucherID;
    //     if(AbsentVoucherID1 && this.AbsentVoucherID !== AbsentVoucherID1){
    //         this.AbsentVoucherID=AbsentVoucherID1;
    //         this.checkNotify(AbsentVoucherID1);
    //     }
    // };
    //
    // checkNotify = (AbsentVoucherID) => {
    //     if(AbsentVoucherID) {
    //         this.onView({row:{data:{AbsentVoucherID:AbsentVoucherID}}});
    //         browserHistory.push(Config.getRootPath() + 'W75F2010');
    //         this.AbsentVoucherID=null;
    //     }
    // };
    //
    // getInfo = (props) => {
    //     const {location} = props ? props : {};
    //     const url = new window.URLSearchParams(window.location.search);
    //     if (url && url.get('voucher_id')) {
    //         return {AbsentVoucherID: url.get('voucher_id')};
    //     } else if (location && location.state) {
    //         return {
    //             AbsentVoucherID: location.state.voucher_id,
    //         }
    //     } else {
    //         return {};
    //     }
    // };

    loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: "W75F2010",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.loading.cboEmployees = true;
        this.setState({ loading: this.loading });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({ loading: this.loading });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboProjects = isReset => {
        const param = {
            FormID: "W75F2010",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch,
        };
        this.loading.cboProjects = true;
        this.setState({ loading: this.loading });
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setState({ loading: this.loading });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadDataGrid = VoucherID => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { VoucherDate, skip, limit } = this.filter;
        const { Employee, AppStatusID, Project, PeriodTime, RangePicker } = this.state;
        const param = {
            TransID: VoucherID || "",
            FormID: "W75F2010",
            Language: Config.language || 84,
            EmployeeID: Employee || "",
            LeaveDateFrom: RangePicker?.[0] || null,
            LeaveDateTo: RangePicker?.[1] || null,
            VoucherDate: VoucherDate,
            ProjectID: Project || "",
            AppStatusID: AppStatusID,
            Period: PeriodTime,
            skip: skip,
            limit: limit,
        };
        this.setState({ gridLoading: true });
        this.props.W75F2010Actions.loadGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboPeriod = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
        };

        this.props.W75F2010Actions.loadPeriod(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W75F2010",
            Language: Config.language || 84,
        };

        this.props.W75F2010Actions.loadAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (isReload = false) => {
        if (isReload) this.loadDataGrid();
        this.setState({
            showW75F2010Popup: false,
        });
    };

    render() {
        const { getDataGrid } = this.props;
        const { iPermission, gridLoading, showW75F2010Popup, mode, rowData } = this.state;
        const { skip, limit } = this.filter;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <Modal open={showW75F2010Popup} width={"70%"} onClose={() => this.onCloseModal()}>
                    <ModalHeader title={Config.lang("Lap_de_xuat_dieu_chinh_thu_nhap")} />
                    <W75F2010Popup mode={mode} data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Lap_de_xuat_dieu_chinh_thu_nhap")}>
                    <Button
                        label={Config.lang("Them")}
                        startIcon={"add"}
                        onClick={this.onAdd}
                        color={"primary"}
                        viewType={"filled"}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={getDataGrid && getDataGrid.rows}
                    keyExpr={"EmployeeID"}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    onCellClick={this.onDetail}
                    onDbRowClick={this.onEdit}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    height={Config.getHeightGrid()}
                    totalItems={getDataGrid && getDataGrid.total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} />
                    <Column cellRender={this.renderAction} />
                </GridContainer>
            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getCboProjects: state.general.getCboProjects,
            getDataGrid: state.W75F2010.getDataGrid,
            getCboPeriod: state.W75F2010.getCboPeriod,
            getCboAppStatus: state.W75F2010.getCboAppStatus,
            getCboAbsentType: state.W75F2010.getCboAbsentType,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch),
        })
    )
)(W75F2010);
