/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { MForm } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import W75F2002 from "./../W75F2002/W75F2002";
import { TextField as TextFieldM } from "@material-ui/core";
import moment from "moment";
import CDN from "../../../CDN";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import _ from "lodash";
import TextBox from "devextreme-react/text-box";
import InputMask from "react-input-mask";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import PopoverW752005 from "../W75F2005/W75F2005Popover";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ButtonIcon,
    Dropdown,
    TextInput,
    Toggle,
    DatePicker,
    Button,
    HelperText,
    Attachment,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Divider,
    Icon as Icons,
    Row,
    Col,
    Modal
} from "diginet-core-ui/components";

const { spacing } = useTheme();

const useStyles = makeStyles({
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "#757575",
    },
    customHrTag: {
        width: "100%",
    },
    styleTimePicker: {
        "& .MuiInputBase-input": {
            textAlign: "center",
        },
    },
    textError: {
        "& .MuiFormHelperText-root": {
            marginTop: 0,
            position: "relative",
            top: 0,
        },
    },
    styleErrorMask: {
        "& .dx-texteditor-container": {
            "& .dx-texteditor-input-container::after": {
                content: '""',
                backgroundColor: "transparent !important",
            },
        },
    },
    inputMask: {
        margin: 0,
        "& .MuiInputBase-root": {
            "& .MuiIconButton-root": {
                padding: "0px 12px 0px 0px",
            },
        },
    },
});
class W75F2000Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW75F2000Popup: false,
            existsLeaveTypeID: false,
            isRegisterType: 0,
            iPermissionCb: 0,
            dataGrid: [],
            leaveTypeID: "",
            quantity: "",
            reason: "",
            Note: "",
            attachment: "",
            dateFrom: null,
            dateTo: null,
            firstHoursLeave: null,
            lastHoursLeave: null,
            totalHours: "",
            IsMultipleDate: false,
            disabled: false,
            disabledSave: false,
            dataOldAttachments: [],
            employee: null,
            dataDetail: [],
            dataHistory: { rows: [], total: 0 },
            error: {},
            loading: false,
            gridLoading: false,
            fullDateFrom: 1,
            fullDateTo: 1,
            toggleTable: false,
            toggleTable1: false,
            employeeID: "",
            IsMinUnitRecordLeave: 0,
            MinUnitQuantity: 0,
            errorMask: false,
            checkOneStDayQuan: false,
            checkMaskTotalHour: false,
            anchorEl: undefined,
            type: "",
            dataPopover: null,
            buttonLoading: {
                Approval: false,
                ApprovalCancel: false,
                Cancel: false,
            },
            dataYear: {},
            supporterID: "",
            valueCboSupporterDefault: [],
            RecipientsNotiID: "",
            valueCboRecipientsNotiDefault: [],
            dataMaster: {},

            // data Dropdown
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboSupporters: {
                rows: [],
                total: 0,
            },
            dataCboRecipientsNotis: {
                rows: [],
                total: 0,
            },

            // loading Dropdown
            CboEmployeesLoading: false,
            dataCboSupportersLoading: false,
            dataCboRecipientsNotisLoading: false,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboRecipientsNotis = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboSupporters = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.gridHistory = {
            ref: null,
            skip: 0,
            limit: 10,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.detailLoaded = {
            EmployeeID: "",
            LeaveTypeID: "",
            DateFrom: null,
            DateTo: null,
            FullDateFrom: 0,
            FullDateTo: 0,
            TotalHours: "",
            FirstHoursLeave: null,
            LastHoursLeave: null,
        };
        this.isFirstLoadHistory = false;
        this.isChangeMultipleDate = false;
        this.dataNameRequireFields = [
            { id: "Reason", name: Config.lang("Ly_do") },
            { id: "Attachment", name: Config.lang("Dinh_kem") },
        ];
        this.refPopover = null;
        this.refBtnClicked = React.createRef();
        this.isSetDefaultYear = false;
        this.w75F2002Ref = null;
        this.isMSS = !!_.toNumber(Config.getLocalStorage("MENUTYPEDHR"));
    }
    checkInputHoursLeave = (hour = "", isTotalHour = false) => {
        const dataHour = _.chain(hour).split("");
        return !!hour && isTotalHour
            ? dataHour
                  .filter(n => n === " ")
                  .size()
                  .value() === 1
            : !dataHour
                  .pull(":")
                  .some(n => n === "_")
                  .value();
    };

    loadPermissionCboEmployee = async () => {
        await this.props.generalActions.getPermission(
            "W09F5660",
            isPer => {
                this.setState({ iPermissionCb: isPer });
            },
            true
        );
    };

    /**
     * Load data ComboboxS <Employee, Supporters, RecipientsNotis>
     * @param key {string} on [CboSupportersLoading, CboRecipientsNotis, CboEmployees]
     * @param isReset {boolean}
     */
    loadCboDD = (key, isReset) => {
        // filterCboSupporters || filterCboRecipientsNotis || filterCboEmployees
        const { skip = 0, limit = 50, strSearch: search = "" } = this[`filter${key}`] || {};
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: key === "CboEmployees" ? "W75F2000" : "W75F4000",
            Language: Config.language || "84",
            skip,
            limit,
            search,
        };
        this.setState({ [`${key}Loading`]: true }); // CboSupportersLoading || CboRecipientsNotisLoading || CboEmployeesLoading
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ [`${key}Loading`]: false }); // CboSupportersLoading || cboRecipientsNotisLoading || CboEmployeesLoading
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const _d = data?.rows || [];
            const total = data?.total || 0;
            const dataTemp = {};
            // if key is null, load data with FormID is W74F4000, then save data to dataCboSupporters, dataCboRecipientsNotis
            // else save data to Combobox ---> data<key> <---
            if (!key) {
                dataTemp[`dataCboRecipientsNotis`] = {
                    rows: isReset ? _d : this.state[`dataCboRecipientsNotis`].rows.concat(_d),
                    total: total,
                };
                dataTemp[`dataCboSupporters`] = {
                    rows: isReset ? _d : this.state[`dataCboSupporters`].rows.concat(_d),
                    total: total,
                };
            } else {
                dataTemp[`data${key}`] = {
                    rows: isReset ? _d : this.state[`data${key}`].rows.concat(_d),
                    total: total,
                };
            }
            this.setState(dataTemp);
        });
    };

    loadRequiredFields = () => {
        const params = {
            FormID: "W75F2000",
            ModuleID: "15",
            CheckMode: 0,
        };
        this.setState({ loading: true });
        this.props.generalActions.getRequiredFields(params, error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadSetting = () => {
        this.props.w75F2000Actions.loadSetting(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    getForm = () => {
        const { mode, data } = this.props;
        const param = {
            Language: Config.language || 84,
            TransID: data && data.TransID ? data.TransID : "",
        };
        this.setState({ loading: true });
        this.props.w75F2000Actions.getForm(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                const supportersID = data?.SupporterID?.length > 0 ? data.SupporterID.split(",") : [];
                const supporters = Config.getListUser(supportersID, "EmployeeID");
                const valueCboSupportersDefault =
                    supporters.length > 0
                        ? supporters.map(supporter => ({
                              EmployeeID: supporter?.EmployeeID ?? "",
                              EmployeeName: supporter?.EmployeeName ?? "",
                          }))
                        : [];
                const recipientsNotiID = data?.RecipientsNotiID?.length > 0 ? data.RecipientsNotiID.split(",") : [];
                const recipientsNotis = Config.getListUser(recipientsNotiID, "EmployeeID");
                const valueCboRecipientNotiDefault =
                    recipientsNotis.length > 0
                        ? recipientsNotis.map(recipientsNoti => ({
                              EmployeeID: recipientsNoti?.EmployeeID ?? "",
                              EmployeeName: recipientsNoti?.EmployeeName ?? "",
                          }))
                        : [];
                this.setState(
                    {
                        dataMaster: data,
                        employeeID: data.EmployeeID,
                        employee: user ? user : data,
                        leaveTypeID: data.LeaveTypeID,
                        quantity: data.Quantity,
                        reason: data.Reason,
                        Note: data.Note,
                        dateFrom: data.LeaveDateFrom,
                        dateTo: data.LeaveDateTo,
                        fullDateTo: data.LastAbsDayQuan,
                        fullDateFrom: data.FirstAbsDayQuan,
                        isRegisterType: data.IsRegisterType,
                        firstHoursLeave: data?.FirstHourLeave || null,
                        lastHoursLeave: data?.LastHourLeave || null,
                        totalHours: this._convertHour(data.TotalHours),
                        IsMultipleDate: Boolean(data.IsMultipleDate),
                        valueCboSupporterDefault: valueCboSupportersDefault,
                        supporterID: supportersID,
                        valueCboRecipientsNotiDefault: valueCboRecipientNotiDefault,
                        RecipientsNotiID: recipientsNotiID,
                    },
                    () => {
                        if (mode !== "view") {
                            this.checkRegisterType();
                        }
                    }
                );
            }
            this.setState({ loading: false });
        });
    };

    loadHistory = EmployeeID => {
        const { skip, limit } = this.gridHistory;
        const { dataYear } = this.state;
        const cycleFrom = moment(dataYear?.CycleFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
        const cycleTo = moment(dataYear?.CycleTo, "DD/MM/YYYY").format("YYYY-MM-DD");
        const employeeID = this.isMSS ? EmployeeID : Config.getHREmployeeID();
        const param = {
            TranMonth: _.get(dataYear, "TranMonth", ""),
            TranYear: _.get(dataYear, "TranYear", ""),
            LeaveCycle: _.get(dataYear, "TranYear", ""), // LeaveCycle = TranYear
            EmployeeID: employeeID,
            CycleFrom: moment(cycleFrom).isValid() ? cycleFrom : null,
            CycleTo: moment(cycleTo).isValid() ? cycleTo : null,
            Mode: 1,
            skip,
            limit,
        };
        this.setState({ loading: true });
        this.props.w75F2000Actions.getGridHistory(param, (error, data) => {
            this.setState({ loading: false });
            this.isFirstLoadHistory = false;
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataHistory: data,
                });
            }
        });
    };

    getGridDetail = () => {
        const {
            mode,
            data,
            loadSetting: { IsMode },
        } = this.props;
        const {
            employeeID,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            fullDateFrom,
            fullDateTo,
            IsMultipleDate,
            totalHours,
            firstHoursLeave,
            lastHoursLeave,
            dataYear,
        } = this.state;
        let type = mode === "view" ? "Edit" : "Add";
        const EmployeeID = employeeID || _.get(data, "EmployeeID", "");
        const LeaveTypeID = leaveTypeID || _.get(data, "LeaveTypeID", "");
        const TransID = _.get(data, "TransID", "");
        let checkOneCase = IsMode === 0 || IsMultipleDate;
        const checkDateTo = IsMode === 0 || IsMode === 1 || IsMode === 2;
        const checkLoadGridDetailEdit = mode === "view" && this.isChangeMultipleDate && !IsMultipleDate;
        if (
            _.isEmpty(LeaveTypeID) ||
            _.isEmpty(EmployeeID) ||
            (checkDateTo ? false : _.isEmpty(dateTo)) ||
            _.isEmpty(dateFrom)
        )
            return false;
        const checkTotalHours = _.isEqual(this.detailLoaded.TotalHours, totalHours);
        const checkFirstHoursLeave = _.isEqual(this.detailLoaded.FirstHoursLeave, firstHoursLeave);
        const checkLastHoursLeave = _.isEqual(this.detailLoaded.LastHoursLeave, lastHoursLeave);
        if (
            EmployeeID === this.detailLoaded.EmployeeID &&
            LeaveTypeID === this.detailLoaded.LeaveTypeID &&
            dateFrom === this.detailLoaded.DateFrom &&
            !checkLoadGridDetailEdit &&
            dateTo === this.detailLoaded.DateTo &&
            fullDateFrom === this.detailLoaded.FullDateFrom &&
            fullDateTo === this.detailLoaded.FullDateTo &&
            checkTotalHours &&
            checkFirstHoursLeave &&
            checkLastHoursLeave
        ) {
            return;
        }
        this.detailLoaded = {
            EmployeeID: EmployeeID,
            LeaveTypeID: LeaveTypeID,
            DateFrom: dateFrom,
            DateTo: dateTo ? dateTo : dateFrom,
            FullDateFrom: fullDateFrom,
            FullDateTo: fullDateTo,
            TotalHours: totalHours,
            FirstHoursLeave: firstHoursLeave,
            LastHoursLeave: lastHoursLeave,
        };
        const params = {
            EmployeeID,
            TransID,
            DivisionID: Config.getDivisionID(),
            Quantity: quantity,
            FirstAbsDayQuan: checkOneCase ? (fullDateFrom ? fullDateFrom : 1) : quantity,
            LastAbsDayQuan:
                mode === "view" && this.isChangeMultipleDate && !IsMultipleDate ? 1 : fullDateTo ? fullDateTo : 1,
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo:
                mode === "view" && !IsMultipleDate
                    ? dateFrom
                        ? moment(dateFrom).format("YYYY-MM-DD")
                        : null
                    : dateTo
                    ? dateTo
                    : dateFrom
                    ? moment(dateFrom).format("YYYY-MM-DD")
                    : null,
            LeaveTypeID: leaveTypeID,
            TranMonth: _.get(dataYear, "TranMonth", ""),
            TranYear: _.get(dataYear, "TranYear", ""),
            LeaveCycle: _.get(dataYear, "TranYear", ""),
            Language: Config.language || "84",
        };
        this.setState({ gridLoading: true, loading: true });
        this.props.w75F2000Actions[`getGridDetail${type}`](params, (error, data) => {
            this.setState({ gridLoading: false, loading: false });
            this.isFirstLoadGridDetail = false;
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (!_.isEmpty(data) && _.some(data, { LeaveTypeID: "" })) {
                this.setState({ existsLeaveTypeID: false });
            } else {
                this.setState({ existsLeaveTypeID: true });
            }
            if (data) {
                this.setState({ dataDetail: data, disabledSave: false });
            }
            this.setState({ toggleTable: true });
        });
    };

    loadAttachments = () => {
        const { data } = this.props;
        const isRegisterType = _.get(data, "IsRegisterType", "");
        const param = {
            KeyID: isRegisterType ? _.get(data, "LinkTransID", "") : _.get(data, "TransID"),
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("ERROR", Config.lang("KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    checkRegisterType = (loadGridDetail = false, cb) => {
        const {
            leaveTypeID,
            employeeID,
            quantity,
            dateFrom,
            dateTo,
            isRegisterType,
            fullDateFrom,
            fullDateTo,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            IsMultipleDate,
        } = this.state;
        const {
            fromW75F2005,
            mode,
            loadSetting: { IsMode },
        } = this.props;
        let checkOneCase = IsMode === 0 || IsMultipleDate;
        let checkCaseTwoandThree = IsMode === 1 || IsMode === 2;
        if (!employeeID) return false;
        const param = {
            EmployeeID: employeeID,
            Quantity: quantity,
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo:
                mode === "view" && !IsMultipleDate
                    ? dateFrom
                        ? moment(dateFrom).format("YYYY-MM-DD")
                        : null
                    : dateTo
                    ? dateTo
                    : dateFrom && moment(dateFrom).format("YYYY-MM-DD"),
            Mode: 0,
            Language: Config.language || "84",
            LeaveTypeID: leaveTypeID,
            FormID: "W75F2000",
            IsRegisterType: isRegisterType,
            OnestAbsDayQuan: checkOneCase ? (fullDateFrom ? fullDateFrom : 1) : quantity,
            LastAbsDayQuan:
                mode === "view" && this.isChangeMultipleDate && !IsMultipleDate ? 1 : fullDateTo ? fullDateTo : 1,
            FirstHoursLeave: firstHoursLeave && !firstHoursLeave.includes("_") ? firstHoursLeave : null,
            LastHoursLeave: lastHoursLeave && !lastHoursLeave.includes("_") ? lastHoursLeave : null,
            TotalHours: !IsMultipleDate ? totalHours : "",
            IsMultipleDate: _.toNumber(IsMultipleDate),
        };

        const checkTotalHours = !IsMultipleDate ? totalHours && this.state.errorMask : false;
        if (checkTotalHours) {
            return false;
        }

        this.props.w75F2000Actions.checkRegisterType(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                if (data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("ERROR", message);
                }
                if (checkCaseTwoandThree) {
                    if (!IsMultipleDate) {
                        if (data.Quantity > 1) {
                            this.setState({ checkOneStDayQuan: true });
                        } else {
                            this.setState({ checkOneStDayQuan: false });
                        }
                    }
                }

                this.setState(
                    {
                        quantity: _.isUndefined(fromW75F2005) ? data.Quantity : this.state.quantity,
                    },
                    () => {
                        cb && cb();
                    }
                );
            }
            let checkLoadGridDetail =
                checkCaseTwoandThree &&
                !IsMultipleDate &&
                (mode === "view" && this.isChangeMultipleDate && !IsMultipleDate ? 0 : data.Quantity > 1);
            if (loadGridDetail) {
                if (!checkLoadGridDetail) {
                    this.getGridDetail();
                }
            }
        });
    };

    handleChange = async (key, e) => {
        const { dateTo, dateFrom, employeeID, IsMultipleDate } = this.state;
        const isEqualDate = moment(moment(dateTo).format("YYYY-MM-DD")).isSame(moment(dateFrom).format("YYYY-MM-DD"));
        const value = _.get(e, "value", _.get(e, "target.value", _.get(e, "event.target.value", "")));
        if (!key) return false;
        switch (key) {
            case "fullDateFrom":
                this.setState({ fullDateFrom: value }, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "fullDateTo":
                this.setState({ fullDateTo: value }, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "DateFrom":
                if (isEqualDate) this.setState({ fullDateTo: 1 });
                this.setState({ dateFrom: value }, () => {
                    this.checkRegisterType(true, () => {
                        this.checkChooseTypeLeave();
                    });
                });
                break;
            case "DateTo":
                if (isEqualDate) this.setState({ fullDateTo: 1 });
                this.setState({ dateTo: value }, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "LeaveDate":
                this.setState({
                    dateFrom: value,
                    dateTo: value,
                });
                break;
            case "EmployeeID":
                this.setState({ employeeID: value }, () => {
                    this.checkRegisterType(true);
                    this.loadHistory(value);
                });
                break;
            case "LeaveTypeID":
                this.setState({ leaveTypeID: value }, () => {
                    const {
                        loadSetting: { IsMode },
                    } = this.props;
                    const firstHoursLeave = _.get(this.state, "firstHoursLeave", "");
                    const lastHoursLeave = _.get(this.state, "lastHoursLeave", "");
                    const totalHours = _.get(this.state, "totalHours", "");
                    if (
                        (IsMode === 2 && totalHours && this.checkInputHoursLeave(totalHours)) ||
                        (IsMode === 1 &&
                            firstHoursLeave &&
                            lastHoursLeave &&
                            this.checkInputHoursLeave(firstHoursLeave) &&
                            this.checkInputHoursLeave(lastHoursLeave)) ||
                        // Khi toggle Nhiều ngày (IsMultipleDate=1) và datepicker Từ ngày, Đến ngày, dropdown Nhân viên khác rỗng
                        (!!dateTo && !!dateFrom && !!employeeID && IsMultipleDate) ||
                        (!!dateFrom && !!employeeID && !IsMultipleDate)
                    ) {
                        this.checkRegisterType(true, () => {
                            IsMultipleDate && this.checkChooseTypeLeave();
                        });
                    }
                });
                break;
            case "Reason":
                this.setState({ reason: value });
                break;
            case "Note":
                this.setState({ Note: value });
                break;
            case "FirstHoursLeave":
                this.setState({ firstHoursLeave: value }, () => {
                    if (this.checkInputHoursLeave(value)) {
                        this.setState({ errorMask: false });
                        if (this.checkInputHoursLeave(_.get(this.state, "lastHoursLeave", ""))) {
                            this.checkRegisterType(true, () => {
                                if (_.get(this.state, "lastHoursLeave", "")) {
                                    this.checkChooseTypeLeave();
                                }
                            });
                        }
                    }
                });
                break;
            case "LastHoursLeave":
                this.setState({ lastHoursLeave: value }, () => {
                    if (this.checkInputHoursLeave(value)) {
                        this.setState({ errorMask: false });
                        if (this.checkInputHoursLeave(_.get(this.state, "firstHoursLeave", ""))) {
                            this.checkRegisterType(true, () => {
                                if (_.get(this.state, "firstHoursLeave", "")) {
                                    this.checkChooseTypeLeave();
                                }
                            });
                        }
                    }
                });
                break;
            case "TotalHours":
                let val = value;
                let dataCheckMask = val.split("");
                let dataTotalHour = [" ", "h", " ", " ", " ", "m"];
                if (!dataCheckMask.includes("h")) {
                    dataTotalHour.unshift(val);
                    val = dataTotalHour.join("");
                }
                if (dataCheckMask.length > 0) {
                    this.setState({ checkMaskTotalHour: true }, () => {
                        this.setState({ totalHours: val }, () => {
                            let dataCheckError = this.state.totalHours.split("");
                            dataCheckError.splice(3, 1);
                            if (!dataCheckError.some(val => val === " ")) {
                                this.setState({ errorMask: false });
                            }
                        });
                    });
                } else {
                    this.setState({ checkMaskTotalHour: false });
                }
                if (this.checkInputHoursLeave(value, true)) {
                    this.checkRegisterType(true, () => {
                        this.checkChooseTypeLeave();
                    });
                }
                e.component.option("isValid", true);
                break;
            case "CycleYears":
                this.setState(
                    {
                        dataYear: _.get(e, "data", {}),
                        dateFrom: null, // Clear data
                        dateTo: null, // Clear data
                    },
                    () => {
                        const { employeeID } = this.state;
                        this.loadHistory(employeeID); // Load lại lưới dữ liệu chấm phép
                    }
                );
                break;
            case "SupporterID":
                this.setState({ supporterID: value });
                break;
            case "RecipientsNotiID":
                this.setState({ RecipientsNotiID: value });
                break;
            default:
                break;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { dataCboYears, mode, data } = this.props;
        const { dateFrom, dateTo, dataYear } = this.state;
        const employeeID = _.get(data, "EmployeeID", "");
        if (!_.isEmpty(dataCboYears) && this.isSetDefaultYear === false && _.isEmpty(dataYear)) {
            if (mode === "edit" || mode === "view") {
                // Edit(W75F2000) //Set data theo điều kiện
                if (dateFrom && dateTo) {
                    const leaveDateFrom = moment(moment(dateFrom).format("YYYY-MM-DD")).unix();
                    const filterDataYear = dataCboYears.find(item => {
                        const cycleFrom = moment(moment(item.CycleFrom, "DD/MM/YYYY").format("YYYY-MM-DD")).unix();
                        const cycleTo = moment(moment(item.CycleTo, "DD/MM/YYYY").format("YYYY-MM-DD")).unix();
                        return leaveDateFrom >= cycleFrom && leaveDateFrom <= cycleTo;
                    });
                    this.isSetDefaultYear = true;
                    if (!_.isEmpty(filterDataYear))
                        this.setState({ dataYear: filterDataYear }, () => {
                            if (mode === "view") this.getGridDetail();
                            this.loadHistory(employeeID);
                        });
                }
            } else {
                //Set default giá trị đầu tiên
                this.isSetDefaultYear = true;
                this.setState({ dataYear: dataCboYears[0] }, () => {
                    this.loadHistory(employeeID);
                });
            }
        }
    }

    async componentDidMount() {
        const { mode, data, edit, fromW75F2005 } = this.props;
        const employeeID = _.get(data, "EmployeeID", "");
        const getUser = Config.getUser({ EmployeeID: Config.getHREmployeeID() });
        await this.loadPermissionCboEmployee();
        if (this.isMSS && this.state.iPermissionCb > 2) {
            this.loadCboDD("CboEmployees");
        } else {
            this.setState({
                dataCboEmployees: getUser ? [getUser] : [],
                employee: getUser ? { ...getUser } : null,
                employeeID: Config.getHREmployeeID(),
            });
        }
        this.loadRequiredFields();
        this.loadSetting();
        this.getCboYears();
        this.loadCboDD();
        this.loadCboLeaveTypes();

        if (mode === "view") {
            this.isFirstLoadHistory = true;
            this.getForm();
            this.loadAttachments();
            if ((data && Number(data.AppStatusID) !== 0) || !edit) {
                this.setState({ disabled: false });
            } else {
                if (this.state.iPermissionCb < 3 && !!_.isUndefined(fromW75F2005)) {
                    this.loadHistory(employeeID);
                }
            }
        } else {
            this.checkRegisterType();
        }
    }

    getCboYears = () => {
        this.props.w75F2000Actions.getCboYears(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    checkChooseTypeLeave = () => {
        const { mode, data, loadSetting } = this.props;
        const { IsExceptTypeLeave, IsMode } = loadSetting;
        const { IsMultipleDate } = this.state;
        if (IsExceptTypeLeave !== 1) return;

        const {
            employeeID,
            quantity,
            dateFrom,
            dateTo,
            isRegisterType,
            fullDateFrom,
            firstHoursLeave,
            lastHoursLeave,
            leaveTypeID,
        } = this.state;
        let checkOneCase = IsMode === 0 || IsMultipleDate;
        const TransID = mode !== "add" ? _.get(data, "TransID", "") : "";

        const params = {
            FormID: "W75F2000",
            Mode: 4,
            TransID,
            EmployeeID: employeeID || "",
            IsRegisterType: isRegisterType.toString(),
            FirstHoursLeave: firstHoursLeave ? firstHoursLeave : null,
            LastHoursLeave: lastHoursLeave ? lastHoursLeave : null,
            Quantity: quantity ?? null,
            IsMultipleDate: Number(IsMultipleDate),
            FirstAbsDayQuan: (checkOneCase ? fullDateFrom : quantity) ?? null,
            LeaveTypeID: leaveTypeID || "",
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo:
                mode === "view" && !IsMultipleDate
                    ? dateFrom
                        ? moment(dateFrom).format("YYYY-MM-DD")
                        : null
                    : dateTo
                    ? moment(dateTo).format("YYYY-MM-DD")
                    : dateFrom
                    ? moment(dateFrom).format("YYYY-MM-DD")
                    : null,
        };

        this.props.w75F2000Actions.checkChooseTypeLeave(params, (error, data) => {
            if (error) Config.popup.show("ERROR", error);
            else if (data?.Status === 1) {
                Config.popup.show("ERROR", data.Message);
                this.setState({
                    quantity: data?.Quantity,
                    firstHoursLeave: null,
                    lastHoursLeave: null,
                    totalHours: "",
                });
            }
        });
    };

    onChangePage = page => {
        const { employeeID } = this.state;
        this.gridHistory.skip = page * this.gridHistory.limit;
        this.loadHistory(employeeID);
    };

    onChangePerPage = per => {
        const { employeeID } = this.state;
        this.gridHistory.skip = 0;
        this.gridHistory.limit = per;
        this.loadHistory(employeeID);
    };

    onTypes = e => {
        const { disabled } = this.props;
        if (!e || disabled) return false;
        this.setState({ isRegisterType: Number(e.target.value) }, () => {
            const { dateFrom, dateTo } = this.state;
            if (moment(dateFrom) > moment(dateTo)) {
                this.setState({ dateFrom: dateTo });
            }
            this.checkRegisterType();
        });
    };

    onAttachment = () => {
        if (this.attRef && this.attRef.hasOwnProperty("openFileDialog")) {
            this.attRef.openFileDialog();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = e => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    setStateErrorText(objValue, key) {
        const { errorHelperText } = this.state;
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...errorHelperText,
                ...objValue,
            },
        });
        return Object.keys(objValue).length !== 0;
    }

    _uploadFile = (files, isAsync, cb) => {
        if (!files.length) return null;
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    onSave = async () => {
        const {
            employeeID,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            isRegisterType,
            reason,
            fullDateFrom,
            fullDateTo,
            dataOldAttachments,
            existsLeaveTypeID,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            errorMask,
            dataYear,
            Note,
            supporterID,
            RecipientsNotiID,
            IsMultipleDate,
        } = this.state;

        const { mode, data, getRequiredFields, loadSetting } = this.props;
        const { IsMode } = loadSetting;

        let checkOneCase = IsMode === 0 || IsMultipleDate;
        let checkCaseTwoandThree = IsMode === 1 || IsMode === 2;
        let checkAllCase = IsMode === 0 || checkCaseTwoandThree;
        let checkQuantity = (!IsMultipleDate && checkCaseTwoandThree && quantity > 1) || quantity < 0;

        const listData = Object.keys(this.state);
        let dataRequired = ["EmployeeID", "LeaveTypeID", "LeaveDateFrom", "LeaveDateTo"];
        let checkFieldSaveData = [];
        const Reason = !isRegisterType ? reason : "";
        const ReasonCancel = isRegisterType ? reason : "";
        const TransID = mode !== "add" ? _.get(data, "TransID", "") : "";
        const filterAttachment =
            getRequiredFields && getRequiredFields.filter(item => item.SqlFieldName === "Attachment");
        getRequiredFields &&
            getRequiredFields.forEach(field => {
                if (
                    (field.ValidMode &&
                        field.ValidMode === "O" &&
                        field.SqlFieldName &&
                        listData.indexOf(field.SqlFieldName.toLowerCase()) > -1 &&
                        dataRequired.indexOf(field.SqlFieldName) < 0) ||
                    (filterAttachment[0].ValidMode === "O" &&
                    _.isEmpty(this.attachments) &&
                    _.isEmpty(dataOldAttachments)
                        ? field.SqlFieldName === "Attachment"
                        : "")
                ) {
                    dataRequired.push(field.SqlFieldName);
                }
                if (
                    field.ValidMode &&
                    field.ValidMode === "W" &&
                    listData.indexOf(field.SqlFieldName.toLowerCase()) > -1
                ) {
                    if (_.isEmpty(this.state[field.SqlFieldName.toLowerCase()])) {
                        checkFieldSaveData.push(field.SqlFieldName);
                        if (
                            field.SqlFieldName === "Attachment" &&
                            (!_.isEmpty(dataOldAttachments) || !_.isEmpty(this.attachments))
                        ) {
                            checkFieldSaveData = checkFieldSaveData.filter(f => f !== "Attachment");
                        }
                    }
                }
            });
        const dataResUploadFile = await this._uploadFile(this.attachments, true);
        const arrAttachments = this._getAttachments(dataResUploadFile);
        const param = {
            TransID,
            EmployeeID: employeeID || "",
            DivisionID: Config.getDivisionID(),
            Quantity: quantity,
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo:
                mode === "view" && !IsMultipleDate
                    ? dateFrom
                        ? moment(dateFrom).format("YYYY-MM-DD")
                        : null
                    : dateTo
                    ? moment(dateTo).format("YYYY-MM-DD")
                    : dateFrom
                    ? moment(dateFrom).format("YYYY-MM-DD")
                    : null,
            FirstAbsDayQuan: checkOneCase ? fullDateFrom : quantity,
            LastAbsDayQuan:
                mode === "view" && this.isChangeMultipleDate && !IsMultipleDate ? 1 : fullDateTo ? fullDateTo : 1,
            FirstHoursLeave: firstHoursLeave ? firstHoursLeave : null,
            LastHoursLeave: lastHoursLeave ? lastHoursLeave : null,
            TotalHours: totalHours,
            Language: Config.language || "84",
            LeaveTypeID: leaveTypeID,
            FormID: "W75F2000",
            IsRegisterType: isRegisterType,
            IsMultipleDate: Number(IsMultipleDate),
            Reason,
            Note,
            ReasonCancel,
            TranMonth: _.get(dataYear, "TranMonth", ""),
            TranYear: _.get(dataYear, "TranYear", ""),
            LeaveCycle: _.get(dataYear, "TranYear", ""),
            arrAttachment: JSON.stringify(arrAttachments),
            arrSupporterID: supporterID.length > 0 ? JSON.stringify(supporterID) : "",
            arrRecipientsNotiID: RecipientsNotiID.length > 0 ? JSON.stringify(RecipientsNotiID) : "",
        };
        const validateRules = [];
        dataRequired &&
            dataRequired.forEach(item => {
                let rule;
                const normalCharItem = item.toLowerCase();
                switch (item) {
                    case "EmployeeID":
                        rule = {
                            name: "EmployeeID",
                            rules: ["isRequired"],
                            value: employeeID,
                        };
                        break;
                    case "LeaveDateFrom":
                        rule = {
                            name: "LeaveDateFrom",
                            rules: ["isRequired"],
                            value: dateFrom ? dateFrom : "",
                        };
                        break;
                    case "LeaveDateTo":
                        if (checkAllCase) {
                            rule = {};
                        } else {
                            rule = {
                                name: "LeaveDateTo",
                                rules: ["isRequired"],
                                value: dateTo ? dateTo : "",
                            };
                        }

                        break;
                    case "LeaveTypeID":
                        rule = {
                            name: "LeaveTypeID",
                            rules: ["isRequired"],
                            value: leaveTypeID ? leaveTypeID : "",
                        };
                        break;
                    default:
                        rule = {
                            name: item,
                            rules: ["isRequired"],
                            value: this.state[normalCharItem],
                        };
                        break;
                }
                validateRules.push(rule);
            });
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false; //Neu co loi validate
        if (!existsLeaveTypeID) {
            Config.popup.show("ERROR", Config.lang("Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai"));
            return;
        }
        if (checkQuantity) {
            return;
        }
        if (errorMask) {
            return;
        }
        if (checkFieldSaveData.length > 0) {
            const nameRequired = this.dataNameRequireFields.filter(val => checkFieldSaveData.indexOf(val.id) > -1);
            const arrFieldName = [];
            nameRequired &&
                nameRequired.forEach(val => {
                    arrFieldName.push(val.name);
                });
            const nameField = (arrFieldName && arrFieldName.length > 0 && arrFieldName.join(", ")) || "";
            Config.popup.show(
                "YES_NO",
                Config.lang("Con_thong_tin_truong") +
                    " " +
                    nameField +
                    " " +
                    Config.lang("Ban_chua_nhap.Ban_co_muon_bo_sung_khong"),
                null,
                () => {
                    this._onSave(mode, isRegisterType, param);
                },
                null,
                null,
                Config.lang("co"),
                Config.lang("Luu")
            );
        } else {
            this._onSave(mode, isRegisterType, param);
        }
    };

    _onSave = (mode, isRegisterType, param) => {
        this.setState({ loading: true });
        this.props.w75F2000Actions[mode === "add" ? "saveLeave" : isRegisterType ? "saveLeave" : "editLeave"](
            param,
            async (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    error.customMessage = {
                        F2000E002: "Mode " + Config.lang("Bat_buoc"),
                        F2000E003: "LeaveTypeID " + Config.lang("Bat_buoc"),
                        F2000E004: "IsRegisterType " + Config.lang("Bat_buoc"),
                        F2000E001: Config.lang("Luu_khong_thanh_cong"),
                        SYS013: Config.lang("Dinh_dang_ngay_khong_dung"),
                    };
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    if (data && data.Status === 1) {
                        Config.popup.show("ERROR", data.Message);
                        return false;
                    } else {
                        this._removeCDN();
                        this.onClose(true);
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    }
                }
            }
        );
    };
    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = file => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    handleShowMore = e => {
        const { mode } = this.props;
        if (mode === "view") {
            this.isChangeMultipleDate = true;
        }
        this.setState({ IsMultipleDate: e.target.checked }, () => {
            if (mode === "add") {
                this.setState({
                    quantity: "",
                    dateFrom: null,
                    dateTo: null,
                    firstHoursLeave: null,
                    lastHoursLeave: null,
                    fullDateFrom: 1,
                    fullDateTo: 1,
                    totalHours: "",
                });
            }
        });
    };

    onOpenApproval = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("ERROR", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"));
        } else {
            this.setState(
                {
                    anchorEl: e.currentTarget,
                    type: "check",
                    dataPopover: data,
                },
                () => {
                    if (this.refPopover) {
                        this.refPopover.onCheck();
                    }
                }
            );
        }
    };

    onOpenApprovalCancel = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("ERROR", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"));
        } else {
            this.setState(
                {
                    anchorEl: e.currentTarget,
                    type: "close",
                    dataPopover: data,
                },
                () => {
                    if (this.refPopover) {
                        this.refPopover.onCheck();
                    }
                }
            );
        }
    };

    onClose = isSaved => {
        const { onClose } = this.props;
        if (onClose) onClose(isSaved);
    };

    _convertHour = data => {
        if (!data) return null;
        let hour = data.split(" ");
        if (hour.length > 1) {
            if (hour[0].replace("h", "").length === 1) {
                hour[0] = "0" + hour[0];
            }
            if (hour[1].replace("m", "").length === 1) {
                hour[1] = "0" + hour[1];
            }
            hour = hour.join("");
        }
        return hour;
    };

    _checkMaskRules = data => {
        if (!data) return null;
        return data?.toString().startsWith("2");
    };

    renderApprovalButton = () => {
        const { data } = this.props;
        const { buttonLoading, loading } = this.state;
        const getAppStatusID = _.toNumber(data?.AppStatusID);
        const getIsCancelApproval = _.toNumber(data?.IsCancelApproval);
        const buttonLists = _.filter(
            [
                {
                    text: Config.lang("Duyet"),
                    startIcon: "Approval",
                    loading: buttonLoading.Approval,
                    color: "success",
                    onClick: e => {
                        this.refBtnClicked.current = "Approval";
                        this.onOpenApproval(e, data);
                    },
                    isVisible: !getAppStatusID,
                },
                {
                    text: Config.lang("Bo_duyet"),
                    startIcon: "ApprovalCancel",
                    loading: buttonLoading.ApprovalCancel,
                    color: "danger",
                    onClick: e => {
                        this.refBtnClicked.current = "ApprovalCancel";
                        this.onOpenApprovalCancel(e, data);
                    },
                    isVisible: _.includes([2, 4], getAppStatusID) && !getIsCancelApproval,
                },
                {
                    text: Config.lang("Tu_choi"),
                    startIcon: "Cancel",
                    loading: buttonLoading.Cancel,
                    color: "danger",
                    onClick: e => {
                        this.refBtnClicked.current = "Cancel";
                        this.onOpenApprovalCancel(e, data);
                    },
                    isVisible: !getAppStatusID,
                },
            ],
            "isVisible"
        );
        return _.map(buttonLists, (props, i) => (
            <Button key={i} disabled={loading} viewType={"filled"} {..._.omit(props, ["isVisible"])} />
        ));
    };

    getPlaceholderDD = (status = false) => {
        return status === false ? Config.lang("Chon") : "";
    };


    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || "84",
        };
        this.props.w75F2000Actions.getLeaveTypes(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    render() {
        const classes = useStyles();
        let { getLeaveTypes, data, edit, mode, fromW75F2005, loadSetting, dataCboYears, open } = this.props;
        const { IsMinUnitRecordLeave, MinUnitQuantity, IsMode } = loadSetting;
        let {
            disabled,
            loading,
            error,
            isRegisterType,
            employee,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            reason,
            dataOldAttachments,
            uploading,
            dataCboEmployees,
            fullDateFrom,
            fullDateTo,
            toggleTable,
            dataDetail,
            dataHistory,
            iPermissionCb,
            gridLoading,
            employeeID,
            disabledSave,
            IsMultipleDate,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            checkOneStDayQuan,
            checkMaskTotalHour,
            anchorEl,
            dataPopover,
            type,
            buttonLoading,
            dataYear,
            Note,
            supporterID,
            valueCboSupporterDefault,
            CboSupportersLoading,
            dataCboSupporters,
            valueCboRecipientsNotiDefault,
            CboEmployeesLoading,
            CboRecipientsNotisLoading,
            RecipientsNotiID,
            dataCboRecipientsNotis,
            dataMaster,
        } = this.state;

        const isEqualDate = moment(moment(dateTo).format("YYYY-MM-DD")).isSame(moment(dateFrom).format("YYYY-MM-DD"));
        const isFromW75F2005 = !_.isUndefined(fromW75F2005);
        const approval = _.get(data, "IsApprove", 0);
        const isRegister = isRegisterType === 1;
        const allow = iPermissionCb > 2;
        const appStatusID = _.get(data, "AppStatusID", 0);
        let dataCBoUnitLeave = [{ value: 0.25 }, { value: 0.5 }, { value: 0.75 }, { value: 1 }];
        if (IsMinUnitRecordLeave === 1) {
            switch (MinUnitQuantity) {
                case 0.5:
                    dataCBoUnitLeave = _.pullAt(dataCBoUnitLeave, [1, 3]);
                    break;
                case 1:
                    dataCBoUnitLeave = [_.last(dataCBoUnitLeave)];
                    break;
                default:
                    break;
            }
        }
        const checkCaseTwoandThree = IsMode === 1 || IsMode === 2;
        const isShowColum = IsMode === 0 || checkCaseTwoandThree;
        const checkRuleFirstHour = this._checkMaskRules(firstHoursLeave);
        const checkRuleLastHour = this._checkMaskRules(lastHoursLeave);

        //format data TotalHour
        let hour = totalHours && totalHours.substring(0, totalHours.indexOf("h") + 1).trim();
        let minute =
            totalHours && totalHours.substring(totalHours.indexOf("h") + 1, totalHours.indexOf("m") + 1).trim();
        const checkRuleTotalHour = this._checkMaskRules(hour + minute);
        const dataCheckTotalHour = totalHours ? totalHours?.split("") : [];
        totalHours = hour + minute ? hour + minute : null;

        return (
            <Modal open={open} zIndex={1020} width={"960"} onClose={() => this.onClose(true)}>
                <ModalHeader>
                    <div className={"display_row align-center"}>
                        {Config.lang("Lap_nghi_phep")}
                        <Dropdown
                            disabled={loading}
                            style={{ marginBottom: 0, marginLeft: 20, minWidth: "280px" }}
                            readOnly={
                                !(mode === "add" || mode === "copy" || mode === "edit") || disabled || isFromW75F2005
                            }
                            valueExpr={"CycleYearCustom"}
                            keyExpr={"CycleYearCustom"}
                            displayExpr={"CycleYearCustom"}
                            placeholder={this.getPlaceholderDD(!(mode === "add" || mode === "copy" || mode === "edit"))}
                            onChange={e => this.handleChange("CycleYears", e)}
                            value={_.get(dataYear, "CycleYearCustom", "")}
                            loading={gridLoading}
                            dataSource={dataCboYears}
                        />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <PopoverW752005
                        ref={ref => (this.refPopover = ref)}
                        anchorEl={anchorEl}
                        type={type}
                        data={dataPopover}
                        setLoading={isLoading =>
                            this.setState({
                                loading: isLoading,
                                buttonLoading: { ...buttonLoading, [this.refBtnClicked.current]: isLoading },
                            })
                        }
                        funcLoadGrid={() => this.onClose(true)}
                    />
                    <Row>
                        {employeeID && (
                            <Col xs={12} sm={12} md={12} lg={12} className={"mgt2x"}>
                                <W75F2002
                                    dataMaster={dataMaster}
                                    dataYear={dataYear}
                                    employeeID={employeeID}
                                    loading={loading}
                                />
                                <Divider className={"mgv4x"} color={"dark12"} width={"100%"} />
                            </Col>
                        )}
                        <Col xs={8} md={10}>
                            <Dropdown
                                required
                                clearAble
                                allowSearch
                                error={error && error["EmployeeID"]}
                                disabled={loading || isFromW75F2005 ? false : !allow || !this.isMSS}
                                readOnly={disabled || isFromW75F2005}
                                valueObjectDefault={employee}
                                dataSource={dataCboEmployees.rows}
                                displayExpr={"EmployeeName"}
                                valueExpr={"EmployeeID"}
                                subText={"EmployeeID"}
                                loading={CboEmployeesLoading}
                                total={dataCboEmployees.total}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                value={employeeID}
                                iconExpr={{ key: "UserPictureURL", prefix: Config.getCDNPath() }}
                                label={Config.lang("Nhan_vien")}
                                placeholder={Config.lang("Chon")}
                                searchDelayTime={500}
                                onChange={e => this.handleChange("EmployeeID", e)}
                                onInput={e => {
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.filterCboEmployees.skip = 0;
                                    this.loadCboDD("CboEmployees", true);
                                }}
                                onLoadMore={e => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboDD("CboEmployees");
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={4} md={2}>
                            <Toggle
                                checked={IsMultipleDate}
                                disabled={disabled || isFromW75F2005 || loading}
                                onChange={this.handleShowMore}
                                label={Config.lang("Nhieu_ngay")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                required
                                error={error && error["LeaveTypeID"]}
                                readOnly={disabled || isFromW75F2005}
                                dataSource={
                                    isFromW75F2005
                                        ? [
                                              {
                                                  LeaveTypeID: _.get(data, "LeaveTypeID", ""),
                                                  LeaveTypeName: _.get(data, "LeaveTypeName", ""),
                                              },
                                          ]
                                        : getLeaveTypes
                                }
                                displayExpr={"LeaveTypeName"}
                                valueExpr={"LeaveTypeID"}
                                placeholder={Config.lang("Chon")}
                                disabled={loading || approval === 2}
                                value={leaveTypeID}
                                label={Config.lang("Loai_phep")}
                                onChange={e => this.handleChange("LeaveTypeID", e)}
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={!IsMultipleDate && checkCaseTwoandThree ? 6 : 4}
                            lg={!IsMultipleDate && checkCaseTwoandThree ? 6 : 4}
                        >
                            <DatePicker
                                error={error && error["LeaveDateFrom"]}
                                label={Config.lang("Tu_ngay")}
                                min={moment(_.get(dataYear, "CycleFrom", ""), "DD/MM/YYYY").format("YYYY-MM-DD")}
                                max={moment(_.get(dataYear, "CycleTo", ""), "DD/MM/YYYY").format("YYYY-MM-DD")}
                                value={dateFrom}
                                required
                                disabled={loading}
                                placeholder={"DD/MM/YYYY"}
                                displayFormat={"DD/MM/YYYY"}
                                actionIconAt={"start"}
                                readOnly={disabled || isFromW75F2005}
                                onChange={e => this.handleChange("DateFrom", e)}
                            />
                        </Col>
                        {!IsMultipleDate && (
                            <>
                                {IsMode === 2 && (
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        className={"display_row align-right"}
                                        style={{ position: "relative" }}
                                    >
                                        <TextBox
                                            mask={checkMaskTotalHour ? "sxh ngm" : ""}
                                            placeholder={checkMaskTotalHour ? "" : "02h 30m"}
                                            maskRules={{
                                                s: /[0-2]/,
                                                x: checkRuleTotalHour ? /[0-3]/ : /[0-9]/,
                                                n: /[0-9]/,
                                                g: /[0-9]/,
                                            }}
                                            className={classes.styleErrorMask}
                                            disabled={disabled || isFromW75F2005}
                                            maskChar={" "}
                                            maskInvalidMessage={""}
                                            buttons={[
                                                {
                                                    location: "before",
                                                    name: "clock",
                                                    options: {
                                                        icon: "clock",
                                                        stylingMode: "text",
                                                    },
                                                },
                                            ]}
                                            value={totalHours}
                                            onInput={e => this.handleChange("TotalHours", e)}
                                            onFocusOut={e => {
                                                if (!e) return false;
                                                dataCheckTotalHour.splice(3, 1);
                                                let check = dataCheckTotalHour.some(val => val === " ");
                                                if (check) {
                                                    this.setState({ errorMask: true });
                                                } else {
                                                    this.setState({ errorMask: false });
                                                }
                                                e.component.option("isValid", true);
                                            }}
                                        />
                                        {this.state.errorMask && (
                                            <HelperText
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    position: "absolute",
                                                    bottom: -spacing(4),
                                                }}
                                            >
                                                {Config.lang("Ban_phai_nhap_dung_dinh_dang_vd_00h00m")}{" "}
                                            </HelperText>
                                        )}
                                    </Col>
                                )}
                                {IsMode === 1 && (
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        className={"display_row align-right"}
                                        style={{ position: "relative" }}
                                    >
                                        <InputMask
                                            mask={"12:34"}
                                            maskChar={"_"}
                                            alwaysShowMask={true}
                                            formatChars={{
                                                1: "[0-2]",
                                                2: checkRuleFirstHour ? "[0-3]" : "[0-9]",
                                                3: "[0-5]",
                                                4: "[0-9]",
                                            }}
                                            onBlur={() => {
                                                if (firstHoursLeave) {
                                                    if (!this.checkInputHoursLeave(firstHoursLeave)) {
                                                        this.setState({ errorMask: true });
                                                    } else {
                                                        this.setState({ errorMask: false });
                                                    }
                                                }
                                            }}
                                            value={firstHoursLeave || ""}
                                            onChange={e => this.handleChange("FirstHoursLeave", e)}
                                        >
                                            {() => (
                                                <TextFieldM
                                                    className={classes.inputMask}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <Icons
                                                                style={{ marginRight: 10, opacity: ".6" }}
                                                                className={"far fa-clock"}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </InputMask>

                                        <InputMask
                                            mask={"12:34"}
                                            maskChar={"_"}
                                            alwaysShowMask={true}
                                            formatChars={{
                                                1: "[0-2]",
                                                2: checkRuleLastHour ? "[0-3]" : "[0-9]",
                                                3: "[0-5]",
                                                4: "[0-9]",
                                            }}
                                            onBlur={() => {
                                                if (lastHoursLeave) {
                                                    if (!this.checkInputHoursLeave(lastHoursLeave)) {
                                                        this.setState({ errorMask: true });
                                                    } else {
                                                        this.setState({ errorMask: false });
                                                    }
                                                }
                                            }}
                                            value={lastHoursLeave || ""}
                                            onChange={e => this.handleChange("LastHoursLeave", e)}
                                        >
                                            {() => <TextFieldM className={"mg0"} />}
                                        </InputMask>
                                        {this.state.errorMask && (
                                            <HelperText
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    position: "absolute",
                                                    bottom: -spacing(4),
                                                }}
                                            >
                                                {Config.lang("Ban_phai_nhap_dung_dinh_dang_vd_11:11")}{" "}
                                            </HelperText>
                                        )}
                                    </Col>
                                )}
                            </>
                        )}
                        {!IsMultipleDate && IsMode !== 0 && (
                            <Col xs={12} sm={3}>
                                <TextInput
                                    error={
                                        checkOneStDayQuan
                                            ? Config.lang("Khong_duoc_phep_nghi_qua_mot_ngay")
                                            : quantity < 0
                                            ? Config.lang("So_luong_phep_khong_hop_le")
                                            : ""
                                    }
                                    label={" "}
                                    readOnly
                                    value={quantity === "" ? "1" : String(quantity)}
                                />
                            </Col>
                        )}
                        {!IsMultipleDate && isShowColum && !IsMode && (
                            <Col xs={3}>
                                <Dropdown
                                    dataSource={dataCBoUnitLeave}
                                    clearAble
                                    displayExpr={"value"}
                                    valueExpr={"value"}
                                    disabled={dataCBoUnitLeave.length === 1 || loading}
                                    label={" "}
                                    value={fullDateFrom}
                                    onChange={e => this.handleChange("fullDateFrom", e)}
                                />
                            </Col>
                        )}
                        {IsMultipleDate && (
                            <>
                                <Col xs={12} sm={6} md={2}>
                                    <Dropdown
                                        dataSource={dataCBoUnitLeave}
                                        clearAble
                                        displayExpr={"value"}
                                        valueExpr={"value"}
                                        disabled={dataCBoUnitLeave.length === 1 || loading}
                                        label={" "}
                                        value={fullDateFrom}
                                        onChange={e => this.handleChange("fullDateFrom", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <DatePicker
                                        error={error && error["LeaveDateTo"]}
                                        label={Config.lang("Den_ngay")}
                                        min={moment(_.get(dataYear, "CycleFrom", ""), "DD/MM/YYYY").format(
                                            "YYYY-MM-DD"
                                        )}
                                        max={moment(_.get(dataYear, "CycleTo", ""), "DD/MM/YYYY").format("YYYY-MM-DD")}
                                        value={dateTo}
                                        required
                                        disabled={loading || isFromW75F2005}
                                        placeholder={"DD/MM/YYYY"}
                                        displayFormat={"DD/MM/YYYY"}
                                        actionIconAt={"start"}
                                        readOnly={disabled || isFromW75F2005}
                                        onChange={e => this.handleChange("DateTo", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={2}>
                                    <Dropdown
                                        dataSource={dataCBoUnitLeave}
                                        clearAble
                                        displayExpr={"value"}
                                        valueExpr={"value"}
                                        disabled={dataCBoUnitLeave.length === 1 || isEqualDate || loading}
                                        label={" "}
                                        value={fullDateTo}
                                        onChange={e => this.handleChange("fullDateTo", e)}
                                    />
                                </Col>
                            </>
                        )}
                        {(IsMultipleDate || (!IsMultipleDate && !IsMode)) && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    required
                                    disabled={approval === 2 || loading || (!IsMultipleDate && !IsMode && !dateFrom)}
                                    label={Config.lang("So_luong")}
                                    readOnly
                                    inputProps={{ step: "0.5" }}
                                    value={String(quantity)}
                                />
                            </Col>
                        )}
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                readOnly={isFromW75F2005}
                                maximumSelectionLength={5}
                                multiple
                                clearAble
                                allowSearch
                                valueObjectDefault={mode !== "add" ? valueCboSupporterDefault : null}
                                dataSource={dataCboSupporters.rows}
                                displayExpr={"{EmployeeID} - {EmployeeName}"}
                                valueExpr={"EmployeeID"}
                                loading={CboSupportersLoading}
                                total={dataCboSupporters.total}
                                skip={this.filterCboSupporters.skip}
                                limit={this.filterCboSupporters.limit}
                                value={supporterID}
                                label={Config.lang("Nguoi_ho_tro")}
                                placeholder={Config.lang("Chon")}
                                searchDelayTime={500}
                                onChange={e => this.handleChange("SupporterID", e)}
                                onInput={e => {
                                    this.filterCboSupporters.strSearch = e.target.value;
                                    this.filterCboSupporters.skip = 0;
                                    this.loadCboDD("CboSupporters", true);
                                }}
                                onLoadMore={e => {
                                    this.filterCboSupporters.skip = e.skip;
                                    this.filterCboSupporters.limit = e.limit;
                                    this.loadCboDD("CboSupporters", true);
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                readOnly={isFromW75F2005}
                                maximumSelectionLength={5}
                                multiple
                                clearAble
                                allowSearch
                                valueObjectDefault={mode !== "add" ? valueCboRecipientsNotiDefault : null}
                                dataSource={dataCboRecipientsNotis.rows}
                                total={dataCboRecipientsNotis.total}
                                displayExpr={"{EmployeeID} - {EmployeeName}"}
                                valueExpr={"EmployeeID"}
                                loading={CboRecipientsNotisLoading}
                                skip={this.filterCboRecipientsNotis.skip}
                                limit={this.filterCboRecipientsNotis.limit}
                                value={RecipientsNotiID}
                                label={Config.lang("Nguoi_nhan_thong_bao")}
                                placeholder={Config.lang("Chon")}
                                searchDelayTime={500}
                                onChange={e => this.handleChange("RecipientsNotiID", e)}
                                onInput={e => {
                                    this.filterCboRecipientsNotis.strSearch = e.target.value;
                                    this.filterCboRecipientsNotis.skip = 0;
                                    this.loadCboDD("CboRecipientsNotis", true);
                                }}
                                onLoadMore={e => {
                                    this.filterCboRecipientsNotis.skip = e.skip;
                                    this.filterCboRecipientsNotis.limit = e.limit;
                                    this.loadCboDD("CboRecipientsNotis");
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                error={error && error["Reason"]}
                                label={Config.lang("Ly_do")}
                                value={reason}
                                onChange={e => this.handleChange("Reason", e)}
                                disabled={loading}
                                readOnly={!!disabled || isFromW75F2005}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                error={error && error["Note"]}
                                label={Config.lang("Ghi_chu")}
                                value={Note}
                                onChange={e => this.handleChange("Note", e)}
                                inputProps={{ maxLength: 2000 }}
                                readOnly={!!disabled || isFromW75F2005}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Accordion
                                expanded={toggleTable}
                                onClick={() => this.setState({ toggleTable: !toggleTable })}
                            >
                                <AccordionSummary>{Config.lang("Chi_tiet_phep")}</AccordionSummary>
                                <AccordionDetails>
                                    <GridContainer
                                        keyExpr={"LeaveTypeID"}
                                        loading={gridLoading}
                                        style={{ border: "none" }}
                                        dataSource={dataDetail}
                                        showRowLines={false}
                                        showBorders={false}
                                        showColumnLines={false}
                                        noDataText={Config.lang("No_data")}
                                    >
                                        <Column
                                            dataField={"LeaveTypeID"}
                                            cellRender={this.renderItem}
                                            visible={false}
                                        />
                                        <Column
                                            caption={Config.lang("Loai_phep")}
                                            dataField={"LeaveTypeName"}
                                            cssClass={"text-left"}
                                        />
                                        <Column
                                            caption={Config.lang("Ngay_nghi")}
                                            dataField={"LeaveDate"}
                                            alignment={"center"}
                                            width={"100%"}
                                        />
                                        <Column
                                            caption={Config.lang("So_luong")}
                                            dataField={"DetailQty"}
                                            cssClass={"text-left"}
                                        />
                                    </GridContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Accordion>
                                <AccordionSummary>{Config.lang("Du_lieu_cham_phep")}</AccordionSummary>
                                <AccordionDetails>
                                    <GridContainer
                                        totalItems={dataHistory.total}
                                        itemPerPage={this.gridHistory.limit}
                                        skipPerPage={this.gridHistory.skip}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        pagerFullScreen={false}
                                        keyExpr={"LeaveTypeID"}
                                        style={{ border: "none" }}
                                        dataSource={dataHistory.rows}
                                        showRowLines={false}
                                        showBorders={false}
                                        showColumnLines={false}
                                        typePaging={"remote"}
                                        noDataText={Config.lang("No_data")}
                                    >
                                        <Column
                                            caption={Config.lang("Ngay_phep")}
                                            dataField={"LeaveDateFrom"}
                                            format={"DD/MM/YYYY"}
                                            cssClass={"text-left"}
                                        />
                                        <Column
                                            caption={Config.lang("Loai_phep")}
                                            dataField={"LeaveTypeName"}
                                            alignment={"center"}
                                            width={"100%"}
                                        />
                                        <Column
                                            caption={Config.lang("So_luong")}
                                            dataField={"Quantity"}
                                            cssClass={"text-left"}
                                        />
                                    </GridContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                ref={ref => (this.attRef = ref)}
                                data={dataOldAttachments}
                                onChange={this.onChangeAttachments}
                                readOnly={disabled}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!disabled && edit && (
                        <>
                            <ButtonIcon
                                viewType={"text"}
                                disabled={loading}
                                circular
                                onClick={this.onAttachment}
                                name={"Attachment"}
                            />
                            <Button
                                text={Config.lang("Luu")}
                                disabled={
                                    Number(data?.OnlyWatch) === 1 ||
                                    disabledSave ||
                                    loading ||
                                    uploading ||
                                    gridLoading ||
                                    Number(appStatusID) === 2 ||
                                    isRegister
                                }
                                onClick={this.onSave}
                                color="primary"
                                startIcon={"Save"}
                                viewType={"filled"}
                            />
                        </>
                    )}
                    {isFromW75F2005 && this.renderApprovalButton()}
                </ModalFooter>
            </Modal>
        );
    }
}

W75F2000Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func,
};

export default compose(
    connect(
        state => ({
            getLeaveTypes: state.W75F2000.getLeaveTypes,
            getForm: state.W75F2000.getForm,
            getAttachments: state.W75F2000.getAttachments,
            color: state.main.color,
            getRequiredFields: state.general.getRequiredFields,
            dataCboYears: state.W75F2000.dataCboYears,
            loadSetting: state.W75F2000.loadSetting,
        }),
        dispatch => ({
            w75F2000Actions: bindActionCreators(W75F2000Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    )
)(W75F2000Popup);
