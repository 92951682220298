import { FormControl } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button, InputBase, Label } from "diginet-core-ui/components";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import { MForm } from "../../../common/form-material";
import PopoverAction from "../../../grid-container/popover-action";
import { makeStyles } from "diginet-core-ui/theme";
const useStyles = makeStyles((theme) => ({
    card: {
        width: "590px",
        height: "178px",
        padding: "37px 28px 0px 28px",
        [theme.breakpoints.down('xs')]: {
            width: "300px",
            height: "170px",
        },
    },
    shrink: {
        transform: 'translate(0, 1.5px) scale(1)',
    }
}));

class PopoverW75F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            data: [],
            errorNote: ""
        };
        this.note = (props.data && props.data.LeaveCancelReason) || "";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({ data: this.props.data });
        }
    }

    onSave = async () => {
        const { funcLoadGrid, setLoading = () => {} } = this.props;
        const { data, notes } = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (validate && validate.errorNote) {
            this.setState(validate);
            return false;
        }
        const param = {
            FormID: "W75F2000",
            TransID: _.get(data, "TransID", ""),
            DivisionID: Config.getDivisionID(),
            EmployeeID: _.get(data, "EmployeeID", ""),
            Quantity: _.get(data, "Quantity", ""),
            LeaveDateFrom: moment(_.get(data, "LeaveDateFrom", moment()), "DD-MM-YYYY").format("YYYY-MM-DD"),
            LeaveDateTo: moment(_.get(data, "LeaveDateTo", moment()), "DD-MM-YYYY").format("YYYY-MM-DD"),
            Language: Config.language || "84",
            LeaveTypeID: _.get(data, "LeaveTypeID", ""),
            FistAbsDayQuan: _.get(data, "FirstAbsDayQuan", ""),
            LastAbsDayQuan: _.get(data, "LastAbsDayQuan", ""),
            FirstHoursLeave: _.get(data, "FirstHousrLeave", null),
            LastHoursLeave: _.get(data, "LastHoursLeave", null),
            IsRegisterType: 1,
            ReasonCancel: notes,
            arrAttachment: JSON.stringify([]),
            LinkTransID: data?.TransID ?? '',
        };
        setLoading(true);
        await this.props.w75F2000Actions.saveLeave(param, async (error, data) => {
            setLoading(false);
            if (error) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (error.code) {
                    case "F2000E002":
                        message = "Mode " + Config.lang("Bat_buoc");
                        break;
                    case "F2000E003":
                        message = "LeaveTypeID " + Config.lang("Bat_buoc");
                        break;
                    case "F2000E004":
                        message = "IsRegisterType " + Config.lang("Bat_buoc");
                        break;
                    case "F2000E001":
                        message = Config.lang("Huy_phep_khong_thanh_cong");
                        break;
                    case "SYS013":
                        message = Config.lang("Dinh_dang_ngay_khong_dung");
                        break;
                    default:
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data && data.Status === 1) {
                    Config.popup.show('INFO', data.Message);
                    return false;
                } else {
                    funcLoadGrid && funcLoadGrid();
                    Config.notify.show('success', Config.lang("Huy_phep_thanh_cong"), 2000);
                }
            }
            setLoading(false);
        });
        if (this.popover) this.popover.instance.hide();
    };

    onOpen = (target, data) => {
        const { anchorEl } = this.props;
        if (this.popover) {
            target = target ? target : anchorEl;
            this.setState({
                data: data
            });
            this.popover.instance.show(target);
        }
    };

    onClose = () => {
        if (this.popover) this.popover.instance.hide();
    };

    onNoteChange = (e) => {
        if (!e) return;
        this.setState({
            notes: e.target.value
        });
    };

    render() {
        const classes = useStyles();
        const { type } = this.props;
        const { notes, errorNote } = this.state;

        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    shading={false}
                    style={{ zIndex: 1300 }}
                    onHiding={() => this.setState({ notes: "", errorNote: "" })}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes} style={{ padding: "0px 0px 21px 0px" }}>
                            <Label
                                color={type === "check" ? "primary" : "secondary"}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                    paddingBottom: 10
                                }}
                            >
                                {Config.lang("Ly_do_huy_phep")}
                            </Label>
                            <InputBase
                                value={notes}
                                onChange={this.onNoteChange}
                                status={type === "check" ? "primary" : "secondary"}>
                            </InputBase>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                        <Button
                            size={"medium"}
                            color={"danger"}
                            viewType={"text"}
                            className={"pull-right"}
                            label={Config.lang("Huy_phep")}
                            onClick={() => this.onSave()}
                        />
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }

}

PopoverW75F2000.propTypes = {
    className: PropTypes.string,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
};

export default compose(connect(null,
    (dispatch) => ({
        w75F2000Actions: bindActionCreators(W75F2000Actions, dispatch)
    }), null, { forwardRef: true }
))(PopoverW75F2000);