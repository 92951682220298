/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 30/12/2021
 * @Example
 */

import { LoadPanel } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import * as generalActions from "../../../../redux/general/general_actions";
import * as W75F2008Actions from "../../../../redux/W75/W75F2008/W75F2008_actions";

import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import ApprovalMulti from "../../../common/approval-multi";

import {
    Button,
    ButtonIcon,
    Checkbox,
    DateRangePicker,
    Dropdown,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    TextInput,
    Typography,
    Col,
    Row,
} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";

export const useStyles = makeStyles(theme => ({
    panel: {
        transition: "all .4s",
    },
    panelForm: {
        opacity: 1,
        transition: "all 1s",
        display: props => (props.minimum ? "none" : "")
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    nowrapSpan: {
        "& span": {
            whiteSpace: "nowrap",
        },
    },
    textUppercase: {
        textTransform: "uppercase !important",
    },
    actionToolbarFilter: {
        marginTop: "0px !important",
        backgroundColor: props => (props.minimum ? theme.colors.primary : theme.colors.fill.headerbar),
        "& svg": {
            "& path": {
                // fill: (props) => (props.minimum ? theme.palette.primary.contrastText : '')
            },
        },
        padding: "0 15px",
        marginBottom: 15,
        fontSize: "1rem",
    },
    gap10: {
        gap: 10,
    },
}));

const W75F2008 = props => {
    const { openModal, onOpenModal, getOrgCharts: dataOrgCharts = [] } = props;
    const [minimum, setMinimum] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const [cboOrgChartLoading, setCboOrgChartLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [rangeDate, setRangeDate] = useState([null, null]);
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [showApprovalMulti, setShowApprocalMulti] = useState(null);
    const [filterSearch, setFilterSearch] = useState({
        DateFrom: null,
        DateTo: null,
        Employee: "",
        OrgchartID: [],
    });

    const classes = useStyles({
        minimum,
    });

    const dispatch = useDispatch();

    const filterSearchPages = useRef({
        skip: 0,
        limit: 20,
    });
    const gridRef = useRef(null);
    const gridReference = useRef(null);
    const changePage = useRef(false);
    const tmpSelectedRowData = useRef([]);
    const tmpSelectedRowKeys = useRef([]);
    const dataSource = useRef({
        total: 0,
        rows: [],
    });

    let dataGridFilter = dataGrid;
    if (isShowSelected) {
        dataGridFilter = {
            rows: tmpSelectedRowData.current ?? [],
            total: tmpSelectedRowData.current.length || 0,
        };
    }

    useEffect(() => {
        loadGrid();
        if (_.isEmpty(dataOrgCharts)) loadDataOrgChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadGrid = () => {
        const { skip, limit } = filterSearchPages.current;
        const { OrgchartID, Employee, DateFrom, DateTo } = filterSearch;
        const params = {
            FormID: "W75F2008",
            OrgchartID: JSON.stringify(OrgchartID),
            Employee,
            DateFrom,
            DateTo,
            skip,
            limit,
        };
        setGridLoading(true);
        dispatch(
            W75F2008Actions.loadGrid(params, (error, data) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    const total = _.get(data, "total", 0);
                    const rows = _.get(data, "rows", []);
                    dataSource.current = {
                        total,
                        rows,
                    };
                    if (!isShowSelected) {
                        setDataGrid({
                            total,
                            rows,
                        });
                    }
                }
            })
        );
    };

    const loadDataOrgChart = () => {
        setCboOrgChartLoading(true);
        dispatch(
            generalActions.getOrgCharts({}, error => {
                setCboOrgChartLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
            })
        );
    };

    const search = () => {
        changePage.current = true;
        filterSearchPages.current.skip = 0;
        loadGrid();
    };

    const collapsePanel = (status = false) => {
        if (_.isBoolean(status)) {
            setMinimum(status);
            setTimeout(() => {
                gridReference.current.instance.resize();
            }, 500); // reSize lại lưới sau 5s theo transition
        }
    };

    const changeTypeShow = e => {
        if (!e) return false;
        changePage.current = true;
        const value = e?.value;
        if (_.isBoolean(value)) setIsShowSelected(value);
    };

    const handleChange = (filterName, e) => {
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            case "OrgchartID":
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
            case "Employee":
                filterSearch[filterName] = value;
                break;
            case "RangePicker":
                const DateFrom = _.isArray(value) ? value[0] : value;
                const DateTo = _.isArray(value) ? value[1] : value;
                filterSearch.DateFrom = DateFrom;
                filterSearch.DateTo = DateTo;
                setRangeDate([DateFrom, DateTo]);
                break;
            default:
                break;
        }
    };

    const onChangePage = page => {
        if (_.isNumber(page)) {
            changePage.current = true;
            filterSearchPages.current.skip = page * filterSearchPages.current.limit;
            loadGrid();
        }
    };

    const onChangePerPage = perPage => {
        if (_.isNumber(perPage)) {
            changePage.current = true;
            filterSearchPages.current.skip = 0;
            filterSearchPages.current.limit = perPage;
            loadGrid();
        }
    };

    const handleChecked = (currentSelectedRowsData, keyExpr) => {
        if (_.isEmpty(tmpSelectedRowData.current) && !_.isEmpty(tmpSelectedRowKeys.current))
            tmpSelectedRowKeys.current = [];
        currentSelectedRowsData.forEach(val => {
            if (tmpSelectedRowData.current.indexOf(val) < 0 && !tmpSelectedRowKeys.current.includes(val[keyExpr])) {
                tmpSelectedRowData.current.push(val);
                tmpSelectedRowKeys.current.push(val[keyExpr]);
            }
        });
    };

    const handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        tmpSelectedRowData.current = tmpSelectedRowData.current.filter(e => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        tmpSelectedRowKeys.current = tmpSelectedRowKeys.current.filter(e => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    };

    const onSelectionChanged = e => {
        const mode = "multiple";
        const keyExpr = "TransID";
        const currentSelectedRowsData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (changePage.current === true) {
            if (currentSelectedRowsData.length > 0) {
                handleChecked(currentSelectedRowsData, keyExpr, mode);
            }
            changePage.current = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {
                // UnSelect
                handleUnChecked(currentDeselectedRowKeys, keyExpr);
            } else if (currentSelectedRowsData.length > 0) {
                if (mode === "multiple") {
                    //Multiple Select
                    handleChecked(currentSelectedRowsData, keyExpr, mode);
                }
                if (mode === "single") {
                    //Select one only
                    currentSelectedRowsData.forEach(val => {
                        tmpSelectedRowData.current = [val];
                        tmpSelectedRowKeys.current = [val[keyExpr]];
                    });
                }
            }
        }
        setSelectedRowKeys(e.selectedRowKeys);
    };

    const approval = mode => {
        if (!_.isNumber(mode)) return false;
        if (tmpSelectedRowData.current?.length > 0 || !_.isEmpty(selectedRowKeys)) {
            Config.popup.show("YES_NO", Config.lang(`Ban_co_chac_chan_muon_${mode ? "tu_choi" : "duyet"}`), () => saveApproval(mode));
        } else {
            Config.popup.show("WARNING", Config.lang("Ban_chua_chon_nhan_vien"));
        }
    };

    const saveApproval = mode => {
        setShowApprocalMulti({ mode: mode, data: tmpSelectedRowData.current });
    };

    const leaveDateCellRender = e => {
        const { LeaveDateFrom, LeaveDateTo } = _.get(e, "row.data", {});
        const line = _.isEmpty(LeaveDateFrom) || _.isEmpty(LeaveDateTo) ? "" : "-";
        return `${LeaveDateFrom || ""} ${line} ${LeaveDateTo || ""}`;
    };

    const onCloseApprovalMulti = refresh => {
        setShowApprocalMulti(null);
        if (refresh) onOpenModal(false, true); // Đóng popup lại và load lại lưới cha
        else{
            tmpSelectedRowData.current = [];
            tmpSelectedRowKeys.current= [];
            loadGrid();
        }
    };

    const renderMaxWidthColum = (e, maxWidth = 260) => {
        const value = _.get(e, "value", "");
        return (
            <div title={value} className={classes.ellipsis} {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})}>
                {value}
            </div>
        );
    };

    const column = [
        {
            fixed: true,
            width: 160,
            dataField: "EmployeeID",
            caption: Config.lang("Ma_nhan_vien"),
        },
        {
            fixed: true,
            width: 220,
            dataField: "EmployeeName",
            caption: Config.lang("Ten_nhan_vien"),
        },
        {
            width: 240,
            dataType: "date",
            alignment: "center",
            format: "dd/MM/yyyy",
            dataField: "LeaveDateFrom",
            caption: Config.lang("Ngay_phep"),
            cellRender: leaveDateCellRender,
        },
        {
            width: 120,
            dataField: "LeaveType",
            caption: Config.lang("Loai_phep"),
        },
        {
            minWidth: 100,
            dataField: "Note",
            caption: Config.lang("Ghi_chu"),
            cellRender: e => renderMaxWidthColum(e, 280),
        },
    ];

    return (
        <>
            <Modal
                id={"Popup-W75F2008"}
                zIndex={1020}
                width={"1440"}
                open={openModal}
                onClose={() => {
                    if (onOpenModal) {
                        onOpenModal(false);
                    }
                }}
            >
                <ModalHeader>{Config.lang("Duyet_nghi_phep_hang_loat")}</ModalHeader>
                <ModalBody>
                    <LoadPanel
                        shading={true}
                        showPane={true}
                        showIndicator={true}
                        visible={gridLoading}
                        shadingColor={"rgba(0,0,0,0.4)"}
                        position={{ my: "center", of: "#Popup-W75F2008" }}
                    />
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={minimum ? 1 : 5}
                            lg={minimum ? 1 : 4}
                            className={`mgt15 ${classes.panel}`}
                        >
                            <Row>
                                <ActionToolbar
                                    showBorder={false}
                                    upperCase={false}
                                    alignment={minimum ? "center" : "space-between"}
                                    className={`${classes.actionToolbarFilter}`}
                                >
                                    <div className={`display_row align-center justify-center ${classes.gap10}`}>
                                        <ButtonIcon
                                            circular
                                            name={"Filter"}
                                            size={"medium"}
                                            viewType={"text"}
                                            onClick={() => collapsePanel(false)}
                                        />
                                        {!minimum && (
                                            <Typography
                                                className={classes.nowrap}>
                                                {Config.lang("Tieu_chi_loc")}
                                            </Typography>
                                        )}
                                    </div>
                                    {!minimum && (
                                        <ButtonIcon
                                            circular
                                            name={"ArrowLeft"}
                                            size={"medium"}
                                            viewType={"text"}
                                            onClick={() => collapsePanel(true)}
                                        />
                                    )}
                                </ActionToolbar>
                            </Row>
                            <div className={`form-field mgr4x ${classes.panelForm}`}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className={'display_row align-between'}>
                                        <Checkbox
                                            color={"primary"}
                                            checked={isShowSelected}
                                            className={`${classes.nowrapSpan}`}
                                            label={Config.lang("Hien_thi_du_lieu_da_chon")}
                                            onChange={changeTypeShow}
                                        />
                                        <Typography>{`(${tmpSelectedRowKeys.current?.length ?? 0})`}</Typography>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            startIcon={"Search"}
                                            viewType={"outlined"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Ma_nhan_vien_Ten_nhan_vien")}
                                            value={filterSearch.Employee}
                                            onChange={e => handleChange("Employee", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            multiple
                                            clearAble
                                            keyExpr={"OrgName"}
                                            viewType={"outlined"}
                                            displayExpr={"OrgName"}
                                            valueExpr={"OrgChartID"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            placeholder={Config.lang("Chon")}
                                            loading={cboOrgChartLoading}
                                            dataSource={dataOrgCharts}
                                            value={filterSearch.OrgchartID}
                                            treeViewParentID={"OrgChartParentID"}
                                            treeViewID={"OrgChartID"}
                                            itemMode="treeview"
                                            allowSearch
                                            onChange={e => handleChange("OrgchartID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <DateRangePicker
                                            clearAble
                                            controls
                                            value={rangeDate}
                                            viewType={"outlined"}
                                            label={Config.lang("Ngay_phep")}
                                            onChange={e => handleChange("RangePicker", e)}
                                            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
                                        <Button
                                            color={"primary"}
                                            viewType={"outlined"}
                                            startIcon={"Search"}
                                            text={Config.lang("Tim_kiem")}
                                            onClick={search}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={minimum ? 11 : 7} lg={minimum ? 11 : 8} className={`${classes.panel}`}>
                            <GridContainer
                                ref={gridRef}
                                reference={ref => (gridReference.current = ref)}
                                keyExpr={"TransID"}
                                typePaging={isShowSelected ? "normal" : "remote"}
                                height={"calc(100vh - 230px)"}
                                pagerFullScreen={false}
                                columnAutoWidth={true} // false
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                typeShort={window.innerWidth < 768}
                                disabled={gridLoading}
                                listPerPage={[20, 40, 60, 80]}
                                totalItems={_.get(dataGridFilter, "total", 0)}
                                dataSource={_.get(dataGridFilter, "rows", [])}
                                selection={{
                                    allowSelectAll: true,
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always",
                                }}
                                selectedRowKey={tmpSelectedRowKeys.current}
                                itemPerPage={filterSearchPages.current.limit}
                                skipPerPage={filterSearchPages.current.skip}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                onSelectionChanged={e => onSelectionChanged(e)}
                            >
                                {column.map((item, idx) => (
                                    <Column {...item} key={idx} />
                                ))}
                            </GridContainer>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className={"mgr2x"}
                        color={"success"}
                        viewType={"filled"}
                        startIcon={"Approval"}
                        text={Config.lang("Duyet")}
                        onClick={() => approval(0)}
                    />
                    <Button
                        color={"danger"}
                        viewType={"filled"}
                        startIcon={"Cancel"}
                        text={Config.lang("Tu_choi")}
                        onClick={() => approval(1)}
                    />
                </ModalFooter>
            </Modal>
            {!!showApprovalMulti && (
                <ApprovalMulti
                    FormID={"W75F2005"}
                    mode={showApprovalMulti?.mode}
                    action={{
                        approval: W75F2008Actions.approval,
                        confirmApproval: W75F2008Actions.confirmApproval,
                    }}
                    open={!!showApprovalMulti}
                    column={column.map(i => ({ ...i, fixed: false }))}
                    dataSource={showApprovalMulti?.data}
                    onConfirm={() => onCloseApprovalMulti(true)}
                    onClose={() => onCloseApprovalMulti()}
                />
            )}
        </>
    );
};

W75F2008.propTypes = {
    openModal: PropTypes.bool,
    onOpenModal: PropTypes.func,
};

W75F2008.defaultProps = {
    open: false,
};

export default compose(
    connect(
        state => ({
            getOrgCharts: state.general.getOrgCharts,
        }),
        null
    )
)(W75F2008);
