/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import W75F2000Popup from "./W75F2000Popup";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import UserName from "../../../common/user/user-name";
import UserImage from "../../../common/user/user-image";
import { Chip, Dropdown, DateRangePicker, Col, Typography, Row } from "diginet-core-ui/components";
import ListApproval from "../../../common/list-approval";
import Popover from "./W75F2000Popover";
class W75F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            LeaveDateFrom: null,
            LeaveDateTo: null,
            showW75F2000Popup: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null,
            },
            dataGrid: [],
            total: 0,
            edit: true,
            gridLoading: false,
            employeeLoading: false,
            filterLoading: false,
            EmployeeID: null,

            open: false,
            type: "",
            dataPopover: null,
            anchorEl: null,
            AppStatusID: "",
            IsRegisterType: "",
            LeaveTypeID: "",
            dataCboEmployees: [],
        };
        this.filter = {
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20,
        };
    }

    // loadPermission = async () => {
    //     await this.props.generalActions.getPermission("W75F2000", isPer => {
    //         this.setState({ iPermission: isPer });
    //     });
    // };

    loadCboEmployees = isReset => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2000",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const { dataCboEmployees } = this.state;
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.EmployeeID) || _.isEmpty(item.EmployeeName) ? "" : "-";
                        return { ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` };
                    });
                }
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : _.get(dataCboEmployees, "rows", []).concat(rows),
                        total,
                    },
                });
            }
        });
    };

    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || "84",
        };
        this.props.w75F2000Actions.getLeaveTypes(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboRegisterTypes = () => {
        this.props.w75F2000Actions.getCboRegisterType(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "STANDARD",
            Language: Config.language || 84,
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        Config.callChildForm(
            {
                FormID: "W75F2000",
                keyExpr: "TransID",
                data: this.state.dataGrid,
                onLoad: params => this.listApprovalRef?.loadGrid({voucher_id: params.ID, IsMSS: params.Type}),
                onAction: (e, data) => this.onView({ row: { data: data } }),
            },
            this.props
        );
    }

    async componentDidMount() {
        this.loadCboLeaveTypes();
    }

    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboRegisterTypes();
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            edit: true,
            rowData: null,
            showW75F2000Popup: true,
        });
    };

    onCloseModal = (isSaved = false) => {
        if (isSaved) this.listApprovalRef.loadGrid();
        this.setState({
            showW75F2000Popup: false,
        });
    };

    onView = e => {
        const { data } = e.row;
        this.setState({
            mode: "view",
            edit: true,
            rowData: data,
            showW75F2000Popup: true,
        });
    };

    onOpenAbort = (evt, e) => {
        const { data } = e.row;
        if (this.refPopover) {
            this.refPopover.onOpen(evt.currentTarget, data);
        }
    };

    renderFilters = (params, filterChange) => {
        const { employeeLoading, dataCboEmployees } = this.state;
        const { getLeaveTypes, getCboAppStatus, getRegisterTypes } = this.props;
        return (
            <>
                <Col xs={12}>
                    <DateRangePicker
                        label={Config.lang("Ngay_de_xuat")}
                        defaultValue={[params.LeaveDateFrom, params.LeaveDateTo]}
                        viewType={"outlined"}
                        placeholder={"dd/mm/yyyy"}
                        displayFormat={"DD/MM/YYYY"}
                        controls
                        clearAble
                        onChange={e => filterChange("LeaveDate", e, "Date")}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        viewType={"outlined"}
                        valueExpr={"AppStatusID"}
                        displayExpr={"AppStatusName"}
                        placeholder={Config.lang("Chon")}
                        label={Config.lang("Trang_thai_duyet")}
                        defaultValue={params.AppStatusID}
                        dataSource={getCboAppStatus}
                        onChange={e => filterChange("AppStatusID", e)}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        viewType={"outlined"}
                        valueExpr={"IsRegisterType"}
                        displayExpr={"RegisterType"}
                        placeholder={Config.lang("Chon")}
                        label={Config.lang("Hinh_thuc")}
                        defaultValue={params.IsRegisterType}
                        dataSource={getRegisterTypes}
                        onChange={e => filterChange("IsRegisterType", e)}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        viewType={"outlined"}
                        valueExpr={"LeaveTypeID"}
                        displayExpr={"LeaveTypeName"}
                        placeholder={Config.lang("Chon")}
                        label={Config.lang("Loai_phep")}
                        defaultValue={params.LeaveTypeID}
                        dataSource={getLeaveTypes}
                        onChange={e => filterChange("LeaveTypeID", e)}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        dataSource={_.get(dataCboEmployees, "rows", [])}
                        total={_.get(dataCboEmployees, "total", 0)}
                        skip={this.filterCboEmployees.skip}
                        limit={this.filterCboEmployees.limit}
                        defaultValue={params.EmployeeID}
                        loading={employeeLoading}
                        viewType={"outlined"}
                        valueExpr={"EmployeeID"}
                        renderSelectedItem={"EmployeeName"}
                        label={Config.lang("Nhan_vien")}
                        placeholder={Config.lang("Chon")}
                        displayExpr={"EmployeeCustomName"}
                        onChange={e => filterChange("EmployeeID", e)}
                        onInput={e => {
                            if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                            this.filterCboEmployees.strSearch = _.get(e, "target.value", "");
                            this.filterCboEmployees.timer = setTimeout(() => {
                                this.filterCboEmployees.skip = 0;
                                this.filterCboEmployees.limit = 20;
                                this.loadCboEmployees(true);
                            }, 700);
                        }}
                        onLoadMore={e => {
                            this.filterCboEmployees.skip = e.skip;
                            this.filterCboEmployees.limit = e.limit;
                            this.loadCboEmployees();
                        }}
                    />
                </Col>
            </>
        );
    };

    renderItem = e => {
        const { data } = e.row;

        const renderAmoutRegistBefore = vl => {
            let amout = Math.abs(vl || 0);
            if (amout > 1 && Config.getLocale() === "en") amout += ` ${Config.lang("Ngay3")}s`;
            else amout += ` ${Config.lang("Ngay3")}`;
            return amout;
        };

        return (
            <Row className={`align-center pdt10 pdb10`}>
                <Col style={{ maxWidth: 80 }}>
                    <UserImage data={data} width={64} height={64} />
                </Col>
                <Col>
                    <Typography type={"h3"}>
                        <UserName data={data} allowHover />
                    </Typography>
                    <div className={"gap1x"}>
                        <Typography type={"p2"}>
                            {data.Quantity} {data.LeaveTypeName} - {data.LeaveTypeID} - {data.LeaveDateFrom}
                            {data.LeaveDateTo ? " - " + data.LeaveDateTo : ""}
                        </Typography>
                        <Typography type={"p2"}>
                            {_.join(
                                [
                                    data.AmoutRegistBefore < 0
                                        ? Config.lang("Dang_ky_sau")
                                        : Config.lang("Dang_ky_truoc_d"),
                                    renderAmoutRegistBefore(data.AmoutRegistBefore),
                                ],
                                ": "
                            )}
                        </Typography>
                        {data.AppStatusID > 0 && (
                            <Typography type={"p2"}>
                                {Config.lang("Boi")} {data.ApproverName} - {data.DepartmentID} {data.ApprovalDate}
                            </Typography>
                        )}
                        {data.AppStatusID > 0 && (
                            <Typography type={"p2"}>
                                {Config.lang("Ghi_chu_duyet")}:{data.ApprovalNotes}
                            </Typography>
                        )}
                        {Boolean(data.IsRegisterType) && (
                            <>
                                {data.LeaveCancelReason && (
                                    <Typography type={"p2"}>
                                        {Config.lang("Ly_do_huy_phep")}: {data.LeaveCancelReason || ""}
                                    </Typography>
                                )}
                                <Chip
                                    label={Config.lang("Huy_phep")}
                                    viewType={"filled"}
                                    color={"primary"}
                                    size={"small"}
                                />
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        );
    };

    onReject = async (data, cb) => {
        const param = {
            FormID: "W75F2000",
            DivisionID: Config.getDivisionID(),
            LeaveDateFrom: Config.convertDate(data.LeaveDateFrom, null, "YYYY-MM-DD"),
            LeaveDateTo: Config.convertDate(data.LeaveDateTo, null, "YYYY-MM-DD"),
            IsRegisterType: 1,
            ReasonCancel: data.ApprovalsNotes,
            arrAttachment: JSON.stringify([]),
            LinkTransID: data?.TransID ?? "",
        };
        return this.props.w75F2000Actions.saveLeave(param, cb);
    };

    onDelete = (param, cb) => {
        const params = {
            HostID: "",
            FormID: "W75F2000",
            EmployeeID: param.EmployeeID,
            TransID: param.TransID,
        };
        return this.props.w75F2000Actions.deleteLeave({ ...params, HostID: "" }, cb);
    };

    loadGrid = (param, cb) => {
        const { location } = this.props;
        const params = {
            TransID: param?.voucher_id || "",
            IsMSS: +(location?.state?.menu?.Type === "MSS"),
            ...param,
        };
        return this.props.w75F2000Actions.getGrid(params, (err, data) => {
            cb && cb(err, data);
        });
    };

    onPrint = data => {
        return this.props.w75F2000Actions.exportLeaveOfAbsenceForm({ TransID: data.TransID }, (err, dataPrint) => {
            if (err) Config.popup.show("ERROR", err);
            else if (dataPrint?.URL) {
                const link = document.createElement("a");
                link.href = dataPrint.URL;
                link.download = dataPrint.fileName;
                link.click();
            }
        });
    };

    render() {
        let { iPermission, edit, showW75F2000Popup, mode, rowData, anchorEl, type, dataPopover } = this.state;
        return (
            <React.Fragment>
                {!!showW75F2000Popup && <W75F2000Popup open={showW75F2000Popup} edit={edit} mode={mode} data={rowData} onClose={this.onCloseModal} />}
                <Popover
                    ref={ref => (this.refPopover = ref)}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    funcLoadGrid={() => this.listApprovalRef.loadGrid()}
                />
                <ListApproval
                    ref={refs => (this.listApprovalRef = refs)}
                    FormID={"W75F2000"}
                    keyExpr={"TransID"}
                    title={Config.lang("Nghi_phep")}
                    onAdd={this.onAdd}
                    action={{
                        loadGrid: this.loadGrid,
                        loadStatus: (params, cb) =>
                            this.props.generalActions.getCboAppStatus({ ...params, FormID: "STANDARD" }, cb),
                    }}
                    isLoadPermission
                    filterProps={{
                        placeholder: Config.lang("Tim_kiem_ho_so_nhan_su"),
                        onOpenLoaded: this.onLoadDataFilter,
                    }}
                    renderEmployee={this.renderItem}
                    onLoadedGrid={dataGrid => {
                        this.setState({ dataGrid: dataGrid?.rows  || []});
                    }}
                    renderAction={e => {
                        const { data = {} } = e?.row || {};
                        return {
                            History: true,
                            View: {
                                disabled: Number(data.OnlyWatch) === 1 ? false : !iPermission >= 2,
                                action: this.onView,
                            },
                            Delete: {
                                disabled:
                                    Number(data.OnlyWatch) === 1 ||
                                    Number(data.AppStatusID) !== 0 ||
                                    !(iPermission >= 4),
                                action: this.onDelete,
                            },
                            Cancel: {
                                disabled: Number(data.IsApprove) !== 2,
                                isShow: data.IsHideCancel !== 1,
                                onClick: evt => this.onOpenAbort(evt, e),
                            },
                            PaperFilled: {
                                disabled: data.IsExport !== 1,
                                action: () => this.onPrint(data),
                                tooltip: Config.lang("Xuat_don_tu")
                            },
                        };
                    }}
                    renderFilter={this.renderFilters}
                    onChangePermission={iPer => this.setState({ iPermission: iPer })}
                />
            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getRegisterTypes: state.W75F2000.getRegisterTypes,
            getLeaveTypes: state.W75F2000.getLeaveTypes,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w75F2000Actions: bindActionCreators(W75F2000Actions, dispatch),
        })
    )
)(W75F2000);
